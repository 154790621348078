import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router";
import { HomeTemp } from "../../components/templates/HomeTemp";
import { PreAgendamento } from "../../components/templates/PreAgendamento";
import { Agendamento } from "../../components/templates/Agendamento";
import { NotFound } from "../NotFound";
import { useSSRSafeId } from "@react-aria/ssr";

export const AgendamentoConsulta = () => {
  const { advId, date, time } = useParams();

  const [step, setStep] = useState(1);
  const [idAdv, setIdAdv] = useState(advId);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedTime, setSelectedTime] = useState(time);

  const renderTemp = () => {
    switch (step) {
      case 1:
        return (
          <PreAgendamento
            advId={idAdv}
            date={selectedDate}
            time={selectedTime}
            nextStep={() => setStep(2)}
          />
        );
      case 2:
        return (
          <Agendamento
            advId={idAdv}
            date={selectedDate}
            time={selectedTime}
          />
        );
      default:
        return <PreAgendamento />;
    }
  };

  return renderTemp();
};
