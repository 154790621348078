import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subtitle from "../../atoms/Subtitle";
import { Col, Container, Row } from "react-bootstrap";
import ImgBanner from "../../atoms/ImgBanner";
import Calendario from "../../molecules/Calendario";
import Paragraph from "../../atoms/Paragraph";
import { Spinner } from "react-bootstrap";
import { ptBR } from "date-fns/locale";
import Btn from "../../atoms/Button";
import { Toasts } from "../../utils/Toasts";
import ExcluirAgendaModal from "../../molecules/ExcluirAgendaModal";
import RemoverRestricoesModal from "../../molecules/RemoverRestricoesModal";
import { AgendaService } from "../../../services/AdvogadoServices/AgendaService";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import HorarioDisponivelCard from "../../molecules/HorarioDisponivelCard";
import ConsultaAgendadaCard from "../../molecules/ConsultaAgendadaCard";

const AdvConsultasAgendadas = () => {
  const apiConsultas = new ConsultaService();
  const apiAgenda = new AgendaService();
  const [loading, setLoading] = useState(false);
  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [showExcluirAgendaModal, setShowExcluirAgendaModal] = useState(false);
  const [showRemoverRestricaoModal, setShowRemoverRestricaoModal] =
    useState(false);
  const [horariosLivresDia, setHorariosLivresDia] = useState([]);
  const [consultasAgendadasDia, setConsultasAgendadasDia] = useState([]);

  const [consultasAgendadas, setConsultasAgendadas] = useState([]);
  const [exibirConsultasDoDia, setExibirConsultasDoDia] = useState(true);
  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  useEffect(() => {
    handleVerTodasConsultas();
  }, []);

  const buscarDadosDoDia = (date) => {
    setLoading(true);
    setSelectedDate(date);
    setExibirConsultasDoDia(true);
    const formattedDate = format(date, "yyyy-MM-dd");

    apiAgenda
      .buscarDadosDia(formattedDate)
      .then((response) => {
        setLoading(false);
        if (response.status === true) {
          const dataAtual = new Date(response.data.dataAtual);

          const horariosLivresDia = Array.isArray(response.data?.agenda)
            ? response.data.agenda
                .filter((item) => new Date(item.data) >= dataAtual)
                .map((item) => ({
                  data: item.data,
                  hora_inicio: item.hora_inicio,
                  hora_fim: item.hora_fim,
                  indisponivel: item.indisponivel,
                  showIcon: !item.cliente,
                }))
            : [];

          const consultasDoDia = Array.isArray(response.data?.consultas)
            ? response.data.consultas.map((item) => ({
                idDaConsulta: item.idDaConsulta,
                data: item.data,
                hora_inicio: item.hora_inicio,
                hora_fim: item.hora_fim,
                nome_cliente: item.nome_cliente,
                status: item.status,
              }))
            : [];

          setHorariosLivresDia(horariosLivresDia);
          setConsultasAgendadasDia(consultasDoDia);
        } else {
          setHorariosLivresDia([]);
          setConsultasAgendadasDia([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro na busca dos dados para o dia selecionado:", error);
      });
  };

  const handleVerTodasConsultas = () => {
    apiConsultas
      .listarConsultas()
      .then((response) => {
        if (response.status === true) {
          setConsultasAgendadas(response.data);
          setExibirConsultasDoDia(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro na busca das consultas:", error);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );
  };

  const handleExcluirAgenda = () => {
    setShowExcluirAgendaModal(true);
  };

  const handleCloseAgendaModal = () => {
    setShowExcluirAgendaModal(false);
  };

  const handleRemoverTodasRestricoes = () => {
    setShowRemoverRestricaoModal(true);
  };

  const handleCloseRestricoesModal = () => {
    setShowRemoverRestricaoModal(false);
  };

  return (
    <Container>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() =>
          setOptionsToast({ code: "", message: "", visible: false })
        }
      />
      <Col>
        <Row>
          <Col>
            <Subtitle text="Minha Agenda" />
          </Col>
        </Row>
        <Row className="gap-5">
          <Col
            lg={6}
            className="d-flex flex-column align-items-end justify-content-top gap-3"
          >
            <Calendario selectedDate={(date) => buscarDadosDoDia(date)} />
            <Col className="d-flex gap-5">
              <Btn
                type="danger-sm"
                text="Excluir Agenda disponível"
                onTouchable={handleExcluirAgenda}
              />
              <Btn
                type="danger-sm"
                text="Remover todas as Restrições"
                onTouchable={handleRemoverTodasRestricoes}
              />
              <Btn
                type="secondary-sm"
                text="Ver todas as consultas"
                onTouchable={() => {
                  handleVerTodasConsultas();
                  setSelectedDate(new Date());
                }}
              />
            </Col>
          </Col>

          <Col
            lg={5}
            className="d-flex flex-column justify-content-top"
            style={{ height: "32rem", overflowY: "auto" }}
          >
            {loading ? (
              <Row className="mt-5 pt-5 d-flex align-items-center">
                <Col lg={1}>
                  <Spinner animation="border" role="status"></Spinner>
                </Col>
                <Paragraph text="Buscando agenda, aguarde." />
              </Row>
            ) : (
              <>
                {exibirConsultasDoDia ? (
                  <>
                    {consultasAgendadasDia.length > 0 && (
                      <>
                        <Paragraph text="Consultas agendadas para o dia:" />
                        {consultasAgendadasDia.map((consulta, index) => (
                          <ConsultaAgendadaCard
                            key={index}
                            data={formatDate(consulta.data)}
                            hora={consulta.hora_inicio}
                            nomeCliente={consulta.nome_cliente}
                            consulta_id={consulta.idDaConsulta}
                            showIcon={false}
                            reagendar={false}
                            cancelarAdv={true}
                            cancelarCli={false}
                          />
                        ))}
                      </>
                    )}
                    {horariosLivresDia.length > 0 && (
                      <>
                        <Paragraph text="Horários disponíveis:" />
                        {horariosLivresDia.map((horario, index) => (
                          <HorarioDisponivelCard
                            key={index}
                            data={formatDate(horario.data)}
                            horaInicio={horario.hora_inicio}
                            horaFim={horario.hora_fim}
                            indisponivel={horario.indisponivel}
                            showIcon={horario.showIcon}
                          />
                        ))}
                      </>
                    )}
                    {consultasAgendadasDia.length === 0 &&
                      horariosLivresDia.length === 0 && (
                          <Paragraph text="Não há agenda para este dia." />
                      )}
                  </>
                ) : (
                  <>
                    <Paragraph text="Lista completa de consultas agendadas:" />
                    {consultasAgendadas.map((consulta, index) => (
                      <ConsultaAgendadaCard
                        key={index}
                        data={formatDate(consulta.data)}
                        hora={consulta.hora_inicio}
                        nomeCliente={consulta.nome_cliente}
                        consulta_id={consulta.idDaConsulta}
                        showIcon={false}
                        reagendar={false}
                        cancelarAdv={true}
                        cancelarCli={false}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
      <ExcluirAgendaModal
        showModal={showExcluirAgendaModal}
        handleCloseModal={handleCloseAgendaModal}
      />
      <RemoverRestricoesModal
        showModal={showRemoverRestricaoModal}
        handleCloseModal={handleCloseRestricoesModal}
      />
      <Col>
        <Row className="mt-5">
          <Col>
            <Subtitle text="Produtos" />
          </Col>
        </Row>
        <Row className="mb-5 pb-5">
          <Col lg={6} className="pb-2">
            <Link
              to="https://assistamais.net.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgBanner type="banner6" key="banner1" />
            </Link>
          </Col>
          <Col lg={6} className="pb-2">
            <Link
              to="https://www.usemaisstore.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgBanner type="banner5" key="banner2" />
            </Link>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default AdvConsultasAgendadas;
