import React, { useState, useEffect } from "react";
import "./styles.css";
import { Col, Modal, Form, Row, Container } from "react-bootstrap";
import Btn from "../../atoms/Button";
import Subtitle from "../../atoms/Subtitle";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { Toasts } from "../../utils/Toasts";
import { ptBR } from "date-fns/locale";
import Paragraph from "../../atoms/Paragraph";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import InputArquivo from "../InputArquivo";
import Icons from "../../atoms/Icons";
import ParagraphSmall from "../../atoms/ParagraphSmall";

export const PerfilCliModal = ({ userData, show, onHide, cli_id, fotoCliente }) => {
  const api = new ConsultaService();

  const id = sessionStorage.getItem("id");
  const [comentario, setComentario] = useState("");
  const [arquivoAnexado, setArquivoAnexado] = useState();
  const [comentariosListados, setComentariosListados] = useState([]);

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");


  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  const fecharToast = () => {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  };

  const formatDateTime = (dateString, timeString) => {
    if (!dateString || !timeString) {
      return;
    }
    const formataData = format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );

    const hours = timeString.slice(0, 2);
    const minutes = timeString.slice(3, 5);

    return `${formataData} - ${hours}:${minutes}`;
  };

  const formattedUserData = userData.map((data) => ({
    nomeCliente: data.nome_Cliente,
    data: formatDateTime(data.Data, data.Hora),
    status: data.status_consulta
  }));

  const handleSalvarAnotacoes = async () => {
    try {
      const response = await api.notasAdvogadoHistorico(
        id,
        cli_id,
        comentario,
        arquivoAnexado
      );

      let optionsToast = {
        code: response.mensagem.codigo,
        message: "Anotação salva!",
        visible: true,
      };
      setOptionsToast(optionsToast);

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      let optionsToast = {
        code: "102",
        message: "Não foi possível salvar sua anotação, tente novamente.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    }
  };

  const fetchListarAnotacoes = async () => {
    try {
      if (id && cli_id) {
        const response = await api.listarNotasAdvogadoHistorico(cli_id);
        if (response.status && Array.isArray(response.data)) {
          const comentariosArray = response.data.map(
            (item) => item.ano_comentario
          );
          const arquivosArray = response.data.map((item) => item.ano_arquivo);
  
          setComentariosListados(comentariosArray);
          setArquivoAnexado(arquivosArray);
        }
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchListarAnotacoes();
  }, [id, cli_id]);


  // Funcoes para validar e abrir o base64 pdf
  const isValidBase64Pdf = (base64String) => {
    // Verificações básicas
    if (!base64String) {
      return false;
    }
    return base64String.startsWith('JVBER');
  };

  const openPdf = (base64) => {
    if (isValidBase64Pdf(base64)) {
      // Decodifica a string base64 para um Uint8Array
      const pdfData = atob(base64);
      const pdfArray = new Uint8Array(pdfData.length);
      for (let i = 0; i < pdfData.length; i++) {
        pdfArray[i] = pdfData.charCodeAt(i);
      }

      // Cria o Blob a partir do Uint8Array
      const blob = new Blob([pdfArray], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');
    } else {
      console.log("A string base64 fornecida não é um PDF válido.");
    }
  };


  const handleVerArquivo = async (arquivoAnexado) => {
    if (!arquivoAnexado) {
      console.error('Nenhum arquivo anexado.');
      return;
    }
    try {
      const response = await api.arquivoAnexadoAdv(arquivoAnexado);
      openPdf(response.data.conteudo_base64);
    } catch (error) {
      console.error('Não conseguimos encontrar o documento', error);
    }
  };


  return (
    <Modal show={show} onHide={onHide} size="medium">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="d-flex flex-row align-items-center">
            <Col lg={12} className="d-flex flex-row align-items-center ">
              <Col lg={2}>
                <AvatarPerfil
                  type="B"
                  base64={fotoCliente}
                  width="5rem"
                  height="5rem"
                  padding="0.1rem"
                  margin="1px"
                />
              </Col>
              <Col className="d-flex">
                {formattedUserData.map((data, index) => (
                  <Col className="text-start ms-3" key={index}>
                    {index === 0 && <Subtitle text={data.nomeCliente} />}
                  </Col>
                ))}
              </Col>
            </Col>

            <Row>
              <Col lg={12} className="d-flex justify-content-between mt-2">
                <Col>
                  <Paragraph text="Status da consulta: "></Paragraph>
                </Col>
                <Col>
                  <Paragraph text="Data:"></Paragraph>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex align-items-center">
                <Col>
                  {formattedUserData.map((data, index) => (
                    <Col className="text-start ms-3 mt-2" key={index} lg={12}>
                      {data.status}
                    </Col>
                  ))}
                </Col>
                <Col>
                  {formattedUserData.map((data, index) => (
                    <Col key={index}>
                      <Col>
                        <Col>{data.data}</Col>
                      </Col>
                    </Col>
                  ))}
                </Col>
              </Col>
            </Row>

            <Row className="d-flex flex-column mt-2">
              <Col>
                <ParagraphSmall text="Meus comentários:" />
              </Col>
              <Col
                className="gap-1"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                {comentariosListados.map((comentario, index) =>
                  comentario ? (
                    <Col className="bg-aviso-box p-2 rounded" key={index}>
                      <Paragraph text={comentario} />
                    </Col>
                  ) : null
                )}
              </Col>
            </Row>

            <Row className="d-flex flex-column">
              <Col className="mt-3">
                <Subtitle text="Comentário (Opcional)" />
                <Col className="text-center">
                  <Form className="">
                    <Form.Group>
                      <Col className="text-center">
                        <Form.Control
                          as="textarea"
                          style={{ height: "8rem" }}
                          type="text"
                          maxLength={300}
                          name="comentario"
                          id="comentario"
                          value={comentario}
                          onChange={(e) => setComentario(e.target.value)}
                          placeholder="Nesse campo você pode fornecer comentários, adicionar notas internas e anexar arquivos sobre o cliente. (Máximo 300 caracteres)"
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Col>
            </Row>

            {arquivoAnexado && arquivoAnexado.length > 0 ? (
              <Row className="d-flex flex-column cursor justify-content-center mt-2">
                <Col>
                  <ParagraphSmall text="Meus arquivos:" />
                </Col>
                <Col className="gap-1" style={{ maxHeight: "200px", overflowY: "auto" }}>
                  {arquivoAnexado.map((arquivo, index) => {
                    return arquivo !== null && arquivo !== undefined ? (
                      <div
                        key={arquivo.id /* ou outra chave única */}
                        className="d-flex align-items-center"
                      >
                        <Col lg={2}>
                          <Icons
                            type="exportar"
                            onClick={() => handleVerArquivo(arquivo)}
                          />
                        </Col>
                        <Paragraph className="ms-2" text={arquivo} />
                      </div>
                    ) : null;
                  })}
                </Col>
              </Row>
            ) : null}
            </Row>

            <Row className="d-flex justify-content-center me-4">
              <Col lg={7} className="w-75 justify-content-center">
                <InputArquivo
                  validFileExtensions={[".pdf"]}
                  getFile={(e) => setArquivoAnexado(e)}
                  name={"Somente arquivos PDF (.pdf)"}
                  id={"documento"}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <Btn type="terciary-sm" text="Fechar" onTouchable={onHide} />
                <Btn
                  type="secondary-sm"
                  text="Enviar"
                  onTouchable={handleSalvarAnotacoes}
                />
              </Col>
            </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
