import React, { useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Icons from "../../atoms/Icons";
import ReagendamentoConsultaModal from "../../molecules/ReagendamentoConsultaModal";
import { Toasts } from "../../utils/Toasts";
import { HomeService } from "../../../services/HomeServices/HomeService.js";
import CancelarConsultaAdvModal from "../CancelarConsultaAdvModal/index.js";
import CancelarConsultaCliModal from "../CancelarConsultaCliModal/index.js";

const ConsultaAgendadaCard = ({
  data,
  hora,
  nomeCliente,
  nomeAdvogado,
  consulta_id,
  idAdvogado,
  reagendar,
  cancelarAdv,
  cancelarCli,
}) => {
  const apiModalReagendamento = new HomeService();

  const id = sessionStorage.getItem("id");
  const data_do_dia = new Date();

  const [showReagendarCliModal, setShowReagendarCliModal] = useState(false);
  const [showCancelarAdvModal, setShowCancelarAdvModal] = useState(false);
  const [showCancelarCliModal, setShowCancelarCliModal] = useState(false);

  const [agenda, setAgenda] = useState(null);

  function compareDates(dateStr1, dateStr2) {
    const date1 = parseDate(dateStr1);
    const date2 = parseDate(dateStr2);

    const yearComparison = date1.getFullYear() === date2.getFullYear();

    const monthComparison = date1.getMonth() === date2.getMonth();

    const dayComparison = date1.getDate() === date2.getDate();

    return yearComparison && monthComparison && dayComparison;
  }

  function parseDate(dateStr) {
    const [day, month, year] = dateStr.split("/").map(Number);

    return new Date(year, month - 1, day);
  }

  const [showSpinner, setShowSpinner] = useState(false);

  const defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    setOptionsToast(defaultOptionsToast);
  }

  const videoConferencia = async () => {
    const dataAgendamento = new Date();

    dataAgendamento.setDate(data.split("/")[0]);
    dataAgendamento.setMonth(parseInt(data.split("/")[1]) - 1);
    dataAgendamento.setFullYear(data.split("/")[2]);

    dataAgendamento.setHours(parseInt(hora.split(":")[0]));
    dataAgendamento.setMinutes(parseInt(hora.split(":")[1]));
    dataAgendamento.setSeconds(0);

    dataAgendamento.setMinutes(parseInt(hora.split(":")[1]) - 10);

    // LIBERA VIDEOCHAMADA COM 10 MINUTOS DE ANTECEDÊNCIA
    if (data_do_dia < dataAgendamento) {
      let optionsToast = {
        code: "102",
        message:
          "A videochamada estará disponível 10 minutos antes da sua consulta!",
        visible: true,
      };

      setOptionsToast(optionsToast);

      return false;
    }

    dataAgendamento.setHours(parseInt(hora.split(":")[0]) + 2);
    dataAgendamento.setMinutes(parseInt(hora.split(":")[1]));

    // BLOQUEIA VIDEOCHAMADA APÓS 2HS DO AGENDAMENTO
    if (data_do_dia > dataAgendamento) {
      let optionsToast = {
        code: "102",
        message: "Vídeo chamada não está mais disponível!",
        visible: true,
      };

      setOptionsToast(optionsToast);

      return false;
    }

    setShowSpinner(true);
    const typeUser = sessionStorage.getItem("tipo") == "A" ? "adv" : "cli";

    window.location.href = `/meet/${typeUser}/${consulta_id}`;
  };

  function formatDate(day, month) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // Adiciona um zero à esquerda se o dia ou mês forem menores que 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${year}/${formattedMonth}/${formattedDay}`;
    return formattedDate;
  }

  const handleCloseCancelarCliModal = () => {
    setShowCancelarCliModal(false);
  };
  const handleShowCancelarCliModal = () => {
    setShowCancelarCliModal(true);
  };

  const handleCloseCancelarAdvModal = () => {
    setShowCancelarAdvModal(false);
  };
  const handleShowCancelarAdvModal = () => {
    setShowCancelarAdvModal(true);
  };

  const handleCloseReagendarCliModal = () => {
    setShowReagendarCliModal(false);
  };
  const handleShowReagendarCliModal = async () => {
    try {
      const response = await apiModalReagendamento.buscarAgendaAdv(idAdvogado);
      if (response && response.data) {
        setAgenda(response.data);
        setShowReagendarCliModal(true);
      } else {
        console.error("Erro ao buscar dados da agenda.");
        setShowReagendarCliModal(false);
      }
    } catch (error) {
      console.error("Erro na chamada de API:", error);
      setShowReagendarCliModal(false);
    }
  };

  return (
    <Container className="d-flex justify-items-center card-consulta ">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Container>
        <Row
          lg={12}
          className="shadow rounded border-start border-warning border-3"
        >
          <Col className="d-flex flex-row align-items-center gap-2 ">
            <Col lg={6} className="d-flex flex-column ms-2">
              <Col className="d-flex flex-row align-items-center gap-3">
                <Paragraph text={data} />
                <Col
                  lg={4}
                  className="border border-warning pt-1 px-2  d-flex rounded justify-content-center"
                >
                  <Paragraph text={`${hora}`} className="fw-bold" />
                </Col>
              </Col>
              <Col>
                <Col className="">
                  {nomeAdvogado ? (
                    <ParagraphSmall text={`* Com: ${nomeAdvogado}`} />
                  ) : (
                    <ParagraphSmall text={`* Com: ${nomeCliente}`} />
                  )}
                </Col>
              </Col>
            </Col>
            <Col lg={6} className="d-flex flex-column gap-2 custom-gap">
              <Col
                lg={12}
                className="d-flex align-items-center custom-gap cursor"
                onClick={() => videoConferencia()}
              >
                <Col lg={2}>
                  {showSpinner ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner-border text-primary"
                    >
                      <span></span>
                    </Spinner>
                  ) : (
                    <Icons type="video" />
                  )}
                </Col>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    textDecoration: "underline",
                  }}
                >
                  Chamada de vídeo
                </p>
              </Col>
              <Col
                lg={8}
                className="d-flex align-items-center gap-3 custom-gap"
              >
                {reagendar !== false && (
                  <Col className="d-flex flex-row align-items-center ">
                    <Col
                      className="cursor"
                      lg={3}
                      onClick={() => {
                        handleShowReagendarCliModal();
                      }}
                    >
                      <Icons type={"reagendar"} />
                    </Col>
                    <Col lg={12}>
                      <ParagraphSmall text="Reagendar Consulta" />
                    </Col>
                  </Col>
                )}
              </Col>
              {cancelarAdv ? (
                <Col lg={8} className="d-flex align-items-center gap-3 ">
                  <Col
                    className="cursor"
                    lg={2}
                    onClick={() => {
                      handleShowCancelarAdvModal();
                    }}
                  >
                    <Icons type="cancelar" />
                  </Col>
                  <ParagraphSmall text="Cancelar" />
                </Col>
              ) : null}

              {cancelarCli ? (
                <Col lg={8} className="d-flex align-items-center gap-3 ">
                  <Col
                    className="cursor"
                    lg={2}
                    onClick={() => {
                      handleShowCancelarCliModal();
                    }}
                  >
                    <Icons type="cancelar" />
                  </Col>
                  <ParagraphSmall text="Cancelar" />
                </Col>
              ) : null}
            </Col>
          </Col>
        </Row>
      </Container>

      <CancelarConsultaAdvModal
        showModal={showCancelarAdvModal}
        handleCloseModal={handleCloseCancelarAdvModal}
        idConsulta={consulta_id}
      />

      <CancelarConsultaCliModal
        showModal={showCancelarCliModal}
        handleCloseModal={handleCloseCancelarCliModal}
        idConsulta={consulta_id}
      />

      <ReagendamentoConsultaModal
        show={showReagendarCliModal}
        onHide={handleCloseReagendarCliModal}
        idConsulta={consulta_id}
        idAdvogado={idAdvogado}
      />
    </Container>
  );
};

export default ConsultaAgendadaCard;
