import React, { useEffect, useState } from "react";
import "./styles.css";
import { Form, Col, Container, Row } from "react-bootstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Btn from "../../atoms/Button";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";

export const AdvAgendaInputs = ({ day, enviarDisponibilidadeAgenda, start, end }) => {
  const id = sessionStorage.getItem("id");

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  // useEffect(() => {
  //   if (
  //     functions.validarHorario(startTime) &&
  //     functions.validarHorario(endTime)
  //   ) {
  //     let horarios = { day: day, startTime: startTime, endTime: endTime };
  //     enviarAgenda(horarios);
  //   }
  // }, [startTime, endTime]);

  const handleStartTimeChange = (e) => {
    let horarios = { day: day, startTime: e.target.value, endTime: end };
    // setStartTime(e.target.value);
    enviarDisponibilidadeAgenda(horarios);
  };

  const handleEndTimeChange = (e) => {
    setStartTime(e.target.value);
    let horarios = { day: day, startTime: start, endTime: e.target.value };
    enviarDisponibilidadeAgenda(horarios);
  };

  return (
    <Container className="d-flex align-items-center ">
      <Col className="d-flex align-items-center justify-content-start my-1 my-0 py-0">
        <Col lg={2} className="d-flex justify-content-center rounded me-2 pt-1 bg-secondary">
        <ParagraphSmall text={day} textColor="white" />
        </Col>

        <InputMask
          // name={startTime}
          className="form-control"
          mask="99:99"
          value={start}
          onChange={handleStartTimeChange}
          placeholder="Horário Inicial"
        ></InputMask>
        <Col lg={1} className="d-flex justify-content-center">
          <ParagraphSmall text="às" />
        </Col>

        <InputMask
          mask="99:99"
          // name={endTime}
          value={end}
          className="form-control"
          onChange={handleEndTimeChange}
          placeholder="Horário Final"
        ></InputMask>
        <hr></hr>
      </Col>
    </Container>
  );
};
