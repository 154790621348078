import api from "../Api";

export class ConsultaService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async listarConsultas() {
    return await api
      .get("adm/site/consulta/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
}
