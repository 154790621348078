import React, { useState, useEffect, useMemo, useCallback } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from "react-bootstrap";

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../../molecules/Header';
import ControlledCarousel from '../../organisms/ControlledCarousel';
import HowItWorks from '../../organisms/HowItWorks';
import AdvsCarousel from '../../organisms/AdvsCarousel'
import AdvsCarouselMobile from "../../organisms/AdvsCarouselMobile"
import Footer from '../../molecules/Footer';
import SearchHome from '../../organisms/SearchHome';
import Subtitle from '../../atoms/Subtitle';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Btn from '../../atoms/Button';
import { VerPerguntaForumCli } from '../../molecules/VerPerguntaForumCli';
import { faleConoscoService } from "../../../services/FaleConoscoServices/FaleConoscoServices";
import ControlledCarouselMobile from "../../organisms/ControlledCarouselMobile";
import { PerfilService } from "../../../services/ClienteServices/PerfilService";
import Logo from "../../atoms/Logo";
import { EmailClienteModal } from "../../molecules/EmailClienteModal";

export const HomeCliTemp = () => {

  const navigate = useNavigate();

  const api = new faleConoscoService();
  const api3 = new PerfilService();


  const [perguntas, setPerguntas] = useState([]);



  function removeAccents(text) {
    const accents = 'ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖØòóôõöøÙÚÛÜùúûüÝýÿ';
    const accentsOut = "AAAAAAaaaaaaEEEEeeeeIIIIiiiiOOOOOOooooooUUUUuuuuYYy";

    return text
      .split('')
      .map((letter, index) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join('');
  }

  const emailLogado = sessionStorage.getItem("email");
  const telLogado = sessionStorage.getItem("tel");
  const cli_id = sessionStorage.getItem("id");
  const nomeLogado_1 = sessionStorage.getItem("nome");
  const nomeLogado_2 = nomeLogado_1.split(' ');
  const nomeLogado_3 = nomeLogado_2[0];
  const nomeLogado = (removeAccents(nomeLogado_3) + '_' + cli_id).toLowerCase();


  const handleNavigate = () => {
    navigate("/Forum");
  };

  const [showEmailValidationModal, setShowEmailValidationModal] = useState(false);

  useEffect(() => {
    const emailValidado = sessionStorage.getItem("emailValidado");
    if (emailValidado === "true") {
      setShowEmailValidationModal(false);
    } else {
      setShowEmailValidationModal(true);
    }
  }, []);


  useEffect(() => {
    async function fetchPerguntas() {
      try {
        const response = await api.verPerguntaForum();

        if (Array.isArray(response.data)) {
          setPerguntas(response.data);
        } else {
          console.error("API response is not an array:", response);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPerguntas();
  }, []);


  return (
    <div className='mt-5  pt-5'>
      <Modal
        size="lg"
        show={showEmailValidationModal}
        backdrop="static"
        keyboard={false}
        className="tamanho-modal"
      >
        <EmailClienteModal />
      </Modal>

      <Container fluid className='fluid-bg p-0 m-0'>
        <Header />
      </Container>

      <Container fluid className="fluid-bg">
        <Col className="d-none d-md-block">
          <ControlledCarousel />
        </Col>
      </Container>

      <Container fluid className="fluid-bg">
        <Col className="d-flex m-0 p-0 d-md-none">
          <ControlledCarouselMobile />
        </Col>
      </Container>

      <Container >

        <SearchHome />
      </Container>

      <Container className='my-5 py-5'>
        <HowItWorks />
      </Container>

      <Container className="my-5">
        <Row className="py-5">
          <Col className="d-lg-flex justify-content-center">
            <Col className="">
              <Subtitle text="Tire suas dúvidas com especialistas" />
            </Col>

            <Col className="">
              <Btn
                type="secondary"
                text="Envie sua pergunta"
                isSubmit={false}
                onTouchable={handleNavigate}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <VerPerguntaForumCli perguntas={perguntas} exibirBotoes={false} />
          </Col>
        </Row>
      </Container>


      <Container className=" my-5">
        <Col className="d-none d-md-block">
          <AdvsCarousel />
        </Col>
      </Container>

      <Container className=" my-5">
        <Col className="d-flex m-0 p-0 d-md-none">
          <AdvsCarouselMobile />
        </Col>
      </Container>

      <Container fluid className='fluid-bg '>

        <Footer />
      </Container>

    </div>
  );
}



