import React, { useCallback, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../molecules/Header';
import Footer from '../../molecules/Footer';


import CabecalhoPerfilCliente from '../../organisms/CabecalhoPerfilCliente';
import ImgBannerPerfil from '../../atoms/ImgBannerPerfil';
import { AvatarPerfil } from '../../atoms/AvatarPerfil';
import { PerfilClienteForms } from '../../organisms/PerfilClienteForms';
import Subtitle from '../../atoms/Subtitle';
import { PerfilClienteSenha } from "../../organisms/PerfilClienteSenha";
import { PerfilService } from "../../../services/ClienteServices/PerfilService";

export const PerfilClienteTemp = () => {
  const api = new PerfilService();
  const id = sessionStorage.getItem('id');
  const [dadosApi, setDadosApi] = useState(null);
  const [dadosApi2, setDadosApi2] = useState(null);
  const [controlRender, setControlRender] = useState(false);

  useEffect(() => {
    if (dadosApi != null) {
      setControlRender(true)
    }
  }, [dadosApi]);


  useEffect(() => {
    api.postPerfilCliente()
      .then((data) => {
        setDadosApi(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  

  return (
    <body className='mt-5 pt-5'>
      <Container fluid className='p-0 m-0 '>
        <Header />
      </Container>

      <Container fluid className="p-0 m-0 banner-container">
        <ImgBannerPerfil className="img-fluid " />
      </Container>

      <Container>
      { controlRender ? <CabecalhoPerfilCliente data={dadosApi}/> : <></>}   
       
      </Container>

      <Container className='mb-5 pb-5'>
        <Subtitle className="" text="Dados pessoais:" />
        { controlRender ? <PerfilClienteForms data={dadosApi}/> : <></>}   
      </Container>

     

      <Container className='mb-5 pb-5'>
        <Subtitle className="" text="Senha:" />
        { controlRender ? <PerfilClienteSenha data={dadosApi}/> : <></>}
       
    </Container>

      <Container fluid className='p-0 m-0 '>
        <Footer />
      </Container>

    </body>
  );
}