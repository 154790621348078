import React from "react";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  FloatingLabel,
  Row,
  Table,
} from "react-bootstrap";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import { Form, FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import { RelatorioParticularService } from "../../../services/AdmFinanceiroServices/RelatorioParticularService";
import Subtitle from "../../atoms/Subtitle";
import Spinner from "react-bootstrap/Spinner";
import { AdmStatusParticularModal } from "../../molecules/AdmStatusParticularModal";
import DropdownBtnParcelas from "../../atoms/DropdownBtnParcelas";

export const AdmRelatorioParticular = () => {
  const api = new RelatorioParticularService();

  const [primeiroPeriodo, setPrimeiroPeriodo] = useState();
  const [segundoPeriodo, setSegundoPeriodo] = useState();
  const [data, setData] = useState();

  const [arrayIdParcelas, setArrayIdParcelas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const [status, setStatus] = useState(null);
  const opcoes = [
    "Todos",
    "Em aberto",
    "Pago",
    "Não pago",
    "Pagamento parcial",
  ];

  const handleOpenModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleAmarzenaId = (parcelasId, isChecked) => {
    if (isChecked === true) {
      setArrayIdParcelas((prevArray) => [...prevArray, parcelasId]);
    } else {
      setArrayIdParcelas((prevArray) =>
        prevArray.filter((id) => id !== parcelasId)
      );
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      let response = await api.listar(primeiroPeriodo, segundoPeriodo);
        response.data.sort((a, b)=> {
          return b.dataCreditoTmstmp - a.dataCreditoTmstmp;
        })
      setData(response);
      // console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Erro na chamada da API:", error);
    }
  };

  useEffect(() => {
    const fetchFirstData = async () => {
      try {
        setLoading(true);
        let today = new Date();
        let nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setDate(1);
        nextMonth.setDate(nextMonth.getDate() - 1);
        let first = `01-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
        let second = `${nextMonth.getDate()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;

        setPrimeiroPeriodo(first);
        setSegundoPeriodo(second);
        let response = await api.listar(first, second);
        response.data.sort((a, b)=> {
          return b.dataCreditoTmstmp - a.dataCreditoTmstmp;
        })
        // console.log(response.data);
        setData(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Erro ao obter taxas:", error);
      }
    };
  
    fetchFirstData();
  }, []);
  

  const exportToCSV = () => {
    const csvData = data.data.map((item, index) => {
      return {
        consultaID: item.consulta_id,
        status: item.status,
        valorRepasse: item.valor_repasse?.replace('R$','').replace('.','').replace(',','.'),
        banco: item.banco,
        agencia: item.agencia,
        conta: item.conta,
        nomeAdvogado: item.nome_advogado_recebedor,
        cpfAdvogado: item.cpf_advogado_recebedor,
        nomeCliente: item.nome_cliente,
        cpfCliente: item.cpf_cliente,
        dataConsulta: item.data_consulta,
        valorConsulta: item.valor_consulta?.replace('R$','').replace('.','').replace(',','.'),
        quantidadeParcelas: item.qtd_parcelas,
        valorParcela: item.valor_parcela?.replace('R$','').replace('.','').replace(',','.'),
        taxaFacajus: item.taxa_facajus?.replace('R$','').replace('.','').replace(',','.'),
        // taxaTerceiro: item.taxa_terceiro,
      }
    });

    const csvContent = [
      [
        "Consulta ID",
        "Status",
        "Valor do Repasse",
        "Banco",
        "Agencia",
        "Conta",
        "Nome do Advogado",
        "Cpf do Advogado",
        "Nome Cliente",
        "Cpf do Cliente",
        "Data da Consulta",
        "Valor da Consulta",
        "Quantidades de Parcelas",
        "Valor da Parcela",
        "Taxa do FaçaJus",
        // "Taxa de Terceiro",
      ],
      console.log("csv data esta aqui ->", csvData),
      ...csvData.map((item) =>
        [
          item.consultaID,
          item.status,
          item.valorRepasse,
          item.banco,
          item.agencia,
          item.conta,
          item.nomeAdvogado,
          item.cpfAdvogado,
          item.nomeCliente,
          item.cpfCliente,
          item.dataConsulta,
          item.valorConsulta,
          item.quantidadeParcelas,
          item.valorParcela,
          item.taxaFacajus,
          // item.taxaTerceiro,
        ].join(",")
      ),
    ].join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Row className="">
        <Col
          lg={12}
          className="m-0 p-0 justify-content-start d-flex text-start"
        >
          <Breadcrumb className="p-0 m-0">
            <Breadcrumb.Item>Financeiro</Breadcrumb.Item>
            <Breadcrumb.Item active>Particular</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="d-flex justify-content-between">
        <Subtitle text="Relatório de Repasses (Asaas):" />
        <Col lg={8} className="d-flex align-items-center gap-2">
          <Col lg={3}>
            <InputMask
              className="form-control"
              mask="99-99-9999"
              value={primeiroPeriodo}
              onChange={(e) => setPrimeiroPeriodo(e.target.value)}
              placeholder="Filtro por período"
            ></InputMask>
          </Col>

          <ParagraphSmall text="à" />

          <Col lg={3}>
            <InputMask
              className="form-control"
              mask="99-99-9999"
              value={segundoPeriodo}
              onChange={(e) => setSegundoPeriodo(e.target.value)}
              placeholder="Filtro por período"
            ></InputMask>
          </Col>

          <Col lg={1} className="cursor">
            {loading ? (
              <Spinner
                style={{ width: "1.4rem", height: "1.4rem" }}
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Icons type="buscar" onClick={fetchData} />
            )}
          </Col>
          <Col lg={7}>
            <Form.Group controlId="parcelas">
              <FloatingLabel className="d-flex align-items-center ms-1">
                <Col lg={12} className="d-flex gap-1 align-items-center">
                  <ParagraphSmall text="Filtrar por status:" />
                  <Col lg={7}>
                    <DropdownBtnParcelas
                      text="Selecione os status"
                      defaultValue={{
                        label: "Todos",
                        value: "Todos",
                      }}
                      buttonType="primary"
                      options={opcoes.map((opcao) => ({
                        label: opcao,
                        value: opcao,
                      }))}
                      onPress={(data) => setStatus(data)}
                      />
                  </Col>
                </Col>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Col>

        <Col lg={4} className="d-flex gap-2 ">
          <Col className="d-flex text-end align-items-center">
            <Col lg={10} className="">
              <Paragraph text="EXPORTAR DADOS" />
            </Col>
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="d-flex flex-column">
        <Col
          className="overflow-auto d-flex  "
          // style={{ maxWidth: "50%"  }}
        >
          <Table striped hover className="">
            <thead>
              <tr>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="#" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Selecionar" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Status" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Consulta ID" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Repasse" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Banco" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Agência" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Conta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome Advogado Recebedor" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF Advogado Recebedor" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Data Consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Quantidade Parcelas" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Parcela" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Taxa Facajus" />
                </th>
                {/* <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Taxa Terceiro" />
                </th> */}
              </tr>
            </thead>
            <tbody>
              {data && data.data.length === 0 ? (
                <tr>
                  <td colSpan="20">Nenhum dado disponível.</td>
                </tr>
              ) : (
                data &&
                data.data.map((item, index) => (
                  <tr key={index} className="align-items-center">
                    {status == null || status.value == "Todos" || item.status == status.value ? (
                      <>
                        <td style={{textAlign: "start", verticalAlign: "middle", }}>
                          {index + 1}
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle", }}>
                          <div className="d-flex justify-content-start m-0 p-0">
                            {item.status === "Em aberto" ? (
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onClick={(e) =>
                                  handleAmarzenaId(
                                    item.parcela_id,
                                    e.target.checked
                                  )}
                              />
                            ) : (
                              " "
                            )}
                          </div>
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.status} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.consulta_id} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.valor_repasse} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.banco} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.agencia} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.conta} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.nome_advogado_recebedor} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.cpf_advogado_recebedor} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.nome_cliente} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.cpf_cliente} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.data_consulta} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.valor_consulta} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.qtd_parcelas} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.valor_parcela} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.taxa_facajus} />
                        </td>
                        {/* <td style={{textAlign: "start",  verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.taxa_terceiro} />
                        </td> */}
                      </>
                    ) : (
                      " "
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
        <Col className="mt-2 - d-flex flex-column">
          <Col className="justify-content-end d-flex ">
            <ParagraphSmall text="Selecione as transações que deseja alterar o status e confirme no botão abaixo." />
          </Col>
          <Col className="justify-content-end d-flex ">
            <Btn
              type="secondary-sm"
              text="Marcar como PAGO"
              onTouchable={handleOpenModal}
            />
          </Col>
        </Col>
        <AdmStatusParticularModal
          show={show}
          onHide={handleCloseModal}
          arrayDeParcelas={arrayIdParcelas}
        />
      </Row>
    </Container>
  );
};

export default AdmRelatorioParticular;
