import React, { useState, useEffect } from "react";
import { Col, Container, Pagination, Row, Table, Form } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import Icons from "../../atoms/Icons";
import BarraPesquisaNome, {
  removeAccents,
} from "../../atoms/BarraPesquisaNome";
import { ConsultaService } from "../../../services/AdmConsultaServices/ConsultaService";
import "./styles.css";

const AdmSiteListaConsultas = () => {
  const apiConsultaAdm = new ConsultaService();

  const [currentPage, setCurrentPage] = useState(1);
  const [listaConsultas, setListaConsultas] = useState([]);
  const [filtroNomeAdvogado, setFiltroNomeAdvogado] = useState({
    rawValue: "",
    filteredValue: "",
  });
  const [filtroNomeCliente, setFiltroNomeCliente] = useState({
    rawValue: "",
    filteredValue: "",
  });
  const [filtroData, setFiltroData] = useState("");
  const [filtroHoraInicio, setFiltroHoraInicio] = useState();
  const [filtroStatusConsulta, setFiltroStatusConsulta] = useState("TODOS");
  const [filtroPagamento, setFiltroPagamento] = useState("TODOS");
  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  useEffect(() => {
    const fetchConsultasList = async () => {
      try {
        const response = await apiConsultaAdm.listarConsultas();
        setListaConsultas(response.data || []);
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };
    fetchConsultasList();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Data inválida";
    try {
      return format(
        utcToZonedTime(parseISO(dateString), fusoHorario),
        "dd/MM/yyyy"
      );
    } catch (error) {
      console.error("Erro ao formatar data:", error);
      return "Data inválida";
    }
  };

  const itemsPerPage = 10;
  const totalPages = listaConsultas
    ? Math.ceil(listaConsultas.length / itemsPerPage)
    : 1;

  const renderTableRows = () => {
    const filteredData = listaConsultas.filter((consulta) => {
      const advogadoIncluido =
        !filtroNomeAdvogado.filteredValue ||
        removeAccents(consulta.nomeAdvogado || "").includes(
          filtroNomeAdvogado.filteredValue
        );

      const clienteIncluido =
        !filtroNomeCliente.filteredValue ||
        removeAccents(consulta.nomeCliente || "").includes(
          filtroNomeCliente.filteredValue
        );

      const dataIncluida = !filtroData || consulta.data === filtroData;

      const horaIncluida =
        !filtroHoraInicio || consulta.horaInicio === filtroHoraInicio;

      const statusIncluido =
        filtroStatusConsulta === "TODOS" ||
        consulta.statusConsulta === filtroStatusConsulta;

      const pagamentoIncluido =
        filtroPagamento === "TODOS" || consulta.pagamento === filtroPagamento;

      return (
        advogadoIncluido &&
        clienteIncluido &&
        dataIncluida &&
        horaIncluida &&
        statusIncluido &&
        pagamentoIncluido
      );
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredData.slice(startIndex, endIndex).map((consulta, index) => (
      <tr key={index}>
        <td>{consulta.nomeAdvogado || "N/A"}</td>
        <td>{consulta.nomeCliente || "N/A"}</td>
        <td>{formatDate(consulta.data)}</td>
        <td>{consulta.horaInicio}</td>
        <td>{consulta.statusConsulta || "N/A"}</td>
        <td>{consulta.pagamento || "N/A"}</td>
      </tr>
    ));
  };

  const exportToCSV = () => {
    // Aplica os mesmos filtros usados na renderização da tabela
    const filteredData = listaConsultas.filter((consulta) => {
      const advogadoIncluido =
        !filtroNomeAdvogado.filteredValue ||
        removeAccents(consulta.nomeAdvogado || "").includes(
          filtroNomeAdvogado.filteredValue
        );

      const clienteIncluido =
        !filtroNomeCliente.filteredValue ||
        removeAccents(consulta.nomeCliente || "").includes(
          filtroNomeCliente.filteredValue
        );

      const dataIncluida = !filtroData || consulta.data === filtroData;

      const horaIncluida =
        !filtroHoraInicio || consulta.horaInicio === filtroHoraInicio;

      const statusIncluido =
        filtroStatusConsulta === "TODOS" ||
        consulta.statusConsulta === filtroStatusConsulta;

      const pagamentoIncluido =
        filtroPagamento === "TODOS" || consulta.pagamento === filtroPagamento;

      return (
        advogadoIncluido &&
        clienteIncluido &&
        dataIncluida &&
        horaIncluida &&
        statusIncluido &&
        pagamentoIncluido
      );
    });

    // Se não houver dados filtrados, exibe alerta ou retorna
    if (filteredData.length === 0) {
      alert("Nenhum dado encontrado para exportação.");
      return;
    }

    // Constrói os dados para o CSV
    const csvData = filteredData.map((item) => ({
      NomeAdvogado: item.nomeAdvogado || "N/A",
      NomeCliente: item.nomeCliente || "N/A",
      Data: formatDate(item.data),
      HoraInicio: item.horaInicio || "N/A",
      Status: item.statusConsulta || "N/A",
      Pagamento: item.pagamento || "N/A",
    }));

    // Cria o conteúdo do CSV
    const csvContent = [
      [
        "Nome Advogado",
        "Nome Cliente",
        "Data",
        "Hora Início",
        "Status",
        "Pagamento",
      ],
      ...csvData.map((item) =>
        [
          item.NomeAdvogado,
          item.NomeCliente,
          item.Data,
          item.HoraInicio,
          item.Status,
          item.Pagamento,
        ].join(",")
      ),
    ].join("\n");

    // Gera o arquivo CSV e faz o download
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "consultas.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container fluid>
      <Row>
        <Col
          lg={12}
          className="d-flex justify-content-between align-items-center mb-3"
        >
          <Paragraph text="Consultas" />
          <div className="d-flex align-items-center">
            <Paragraph text="EXPORTAR DADOS" />
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Nome Advogado</th>
                <th>Nome Cliente</th>
                <th>Data</th>
                <th>Hora Inicio</th>
                <th>Status</th>
                <th>Pagamento</th>
              </tr>
              <tr>
                <th>
                  <BarraPesquisaNome
                    placeholder="Pesquisar Advogado"
                    value={filtroNomeAdvogado}
                    onChange={setFiltroNomeAdvogado}
                  />
                </th>
                <th>
                  <BarraPesquisaNome
                    placeholder="Pesquisar Cliente"
                    value={filtroNomeCliente}
                    onChange={setFiltroNomeCliente}
                  />
                </th>
                <th>
                  <Form.Control
                    type="date"
                    value={filtroData}
                    onChange={(e) => setFiltroData(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    type="time"
                    value={filtroHoraInicio}
                    onChange={(e) => setFiltroHoraInicio(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    as="select"
                    value={filtroStatusConsulta}
                    onChange={(e) => setFiltroStatusConsulta(e.target.value)}
                  >
                    <option>TODOS</option>
                    <option>AGENDADA</option>
                    <option>CANCELADA</option>
                    <option>REAGENDADA</option>
                    <option>FINALIZADA</option>
                  </Form.Control>
                </th>
                <th>
                  <Form.Control
                    as="select"
                    value={filtroPagamento}
                    onChange={(e) => setFiltroPagamento(e.target.value)}
                  >
                    <option>TODOS</option>
                    <option>PLANO</option>
                    <option>PARTICULAR</option>
                    <option>CONSIGNADO</option>
                  </Form.Control>
                </th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </Col>
        <Col
          lg={12}
          className="d-flex align-items-center justify-content-center"
        >
          <Pagination size="sm">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmSiteListaConsultas;
