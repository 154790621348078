import React, { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import "./styles.css";
import Button from "../../atoms/Button";
import Form from "react-bootstrap/Form";
import Subtitle from "../../atoms/Subtitle";
import { Field, Formik, useFormik } from "formik";
import * as yup from "yup";
import { CadastroService } from "../../../services/ClienteServices/CadastroService";
import { UserService } from "../../../services/UserServices/UserService";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import { FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { ic_check_circle } from "react-icons-kit/md/ic_check_circle";
import { ic_cancel } from "react-icons-kit/md/ic_cancel";
import { Toasts } from "../../utils/Toasts";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import CountdownTimer from "../../molecules/CountdownTimer ";
import TermosDeUsoModal from "../../molecules/TermosDeUsoModal";
import PoliticaPrivacidadeModal from "../../molecules/PoliticaPrivacidadeModal";
import ProgressBar from "react-bootstrap/ProgressBar";

export const CadastroClienteForms = () => {
  let cli_id = sessionStorage.getItem("id");
  let api = new CadastroService();
  let apiUser = new UserService();

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");

  const codigoCompleto = code1 + code2 + code3 + code4;

  const handleSair = () => {
    {
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  const [preCadastroLoading, setPreCadastroLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoadingCadastrar, setIsLoadingCadastrar] = useState(false);
  const [percent3, setPercent3] = useState(80);

  const [step, setStep] = useState();
  const [formValues, setFormValues] = useState({});

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const [defaultCpf, setDefaultCpf] = useState("");
  const [defaultNome, setDefaultNome] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultCelular, setDefaultCelular] = useState("");
  const [defaultConfirmaEmail, setDefaultConfirmaEmail] = useState("");
  const [user, setUser] = useState(null);

  const [aceitoTermos, setAceitoTermos] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalPoliticaShow, setModalPoliticaShow] = useState(false);

  const [isPreCadastro, setIsPreCadastro] = useState();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const tipo = "CLIENTE";

  const formik = useFormik({
    initialValues: {
      cpf: "",
      nome: "",
      email: "",
      confirma_email: "",
      celular: "",
    },
    validationSchema: yup.object({
      cpf: yup
        .string()
        .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "Formato de CPF inválido")
        .required("CPF é obrigatório"),
      nome: yup.string().required("Nome é obrigatório"),
      email: yup
        .string()
        .email("Email inválido")
        .required("Email é obrigatório"),
      confirma_email: yup
        .string()
        .oneOf([yup.ref("email"), null], "Os emails devem ser iguais")
        .required("Confirmação de email é obrigatória"),
      celular: yup.string().required("Celular é obrigatório"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const voltarStep1 = () => {
    setPreCadastroLoading(false);
    setIsPreCadastro(false);
    setIsFieldsDisabled(false);
    setStep((prevStep) => prevStep - 1);
    formik.setFieldValue("nome", "");
    formik.setFieldValue("cpf", "");
    formik.setFieldValue("email", "");
    formik.setFieldValue("confirma_email", "");
    formik.setFieldValue("celular", "");
  };

  const inputRefs = {
    code1: useRef(),
    code2: useRef(),
    code3: useRef(),
    code4: useRef(),
  };

  const [showDialogSenha, setShowDialogSenha] = useState(false);
  const [showDialogConfirmaSenha, setShowDialogConfirmaSenha] = useState(false);

  const [validation, setValidation] = useState({
    hasMinimumLength: false,
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialCharacter: false,
    doesntHasProhibitedCharacter: false,
  });

  const [validationConfirmaSenha, setValidationConfirmaSenha] = useState({
    hasMinimumLength: false,
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialCharacter: false,
    doesntHasProhibitedCharacter: false,
  });

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    if (e.target.name == "senha") {
      setValidation(validatePassword(newPassword));
    } else {
      setValidationConfirmaSenha(validatePassword(newPassword));
    }
  };

  // Função para verificar o comprimento mínimo da senha
  const hasMinimumLength = (password) => {
    return password.length >= 8;
  };
  // Função para verificar se há pelo menos um número na senha
  const hasNumber = (password) => {
    return /[0-9]/.test(password);
  };
  // Função para verificar se há pelo menos uma letra maiúscula na senha
  const hasUpperCase = (password) => {
    return /[A-Z]/.test(password);
  };
  // Função para verificar se há pelo menos uma letra minúscula na senha
  const hasLowerCase = (password) => {
    return /[a-z]/.test(password);
  };
  // Função para verificar se há pelo menos um caractere especial na senha
  const hasSpecialCharacter = (password) => {
    return /[!_@#$&*\.]/.test(password);
  };
  // Função para verificar se não há algum caracter especial que viola as práticas de segurança
  const doesntHasProhibitedCharacter = (password) => {
    return /[/\\'`\-\"%(),]/.test(password);
  };
  // Função de validação completa que retorna um objeto com o estado de cada critério
  const validatePassword = (password) => {
    return {
      hasMinimumLength: hasMinimumLength(password),
      hasNumber: hasNumber(password),
      hasUpperCase: hasUpperCase(password),
      hasLowerCase: hasLowerCase(password),
      hasSpecialCharacter: hasSpecialCharacter(password),
      doesntHasProhibitedCharacter: doesntHasProhibitedCharacter(password),
    };
  };

  const handleTermosClick = () => {
    setModalShow(true);
  };

  const handlePoliticaClick = () => {
    setModalPoliticaShow(true);
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleCpfChangeDebounced = debounce(async (cpf, setFieldValue) => {
    if (functions.formatOnlyNumbers(cpf).length === 11) {
      try {
        // Chama a API para verificar o pré-cadastro
        const response = await apiUser.verificaPreCadastro(cpf, tipo);

        setPreCadastroLoading(true);

        if (response.status && response.data) {
          const { cli_nome, cli_email, cli_celular } = response.data;

          // Formate o número de celular para o formato esperado antes de setar o valor
          const celularFormatado = formatarCelular(cli_celular);

          // Atualiza os campos subsequentes com os dados do pré-cadastro
          setFieldValue("nome", cli_nome);
          setFieldValue("email", cli_email);
          setFieldValue("confirma_email", cli_email);
          setFieldValue("celular", celularFormatado);

          setIsPreCadastro(true);
          setIsFieldsDisabled(true);

          setFormValues({
            cpf: cpf,
            nome: cli_nome,
            email: cli_email,
            confirma_email: cli_email,
            celular: celularFormatado,
          });

          let optionsToast = {
            code: "101",
            message:
              "Seja bem-vindo! Você foi identificado como cliente do plano Bem Mais Benefícios.",
            visible: true,
            duration: 10000,
          };
          setOptionsToast(optionsToast);

          setTimeout(() => {
            setPreCadastroLoading(false);
            setStep(2);
          }, 10000);
        } else {
          setPreCadastroLoading(false);
          setIsPreCadastro(false);
        }
      } catch (error) {
        setPreCadastroLoading(false);
        console.error("Erro ao verificar o pré-cadastro: ", error);
      }
    }
  }, 500);

  const formatarCelular = (celular) => {
    const ddd = celular.substring(0, 2);
    const parte1 = celular.substring(2, 7);
    const parte2 = celular.substring(7, 11);
    return `(${ddd}) ${parte1}-${parte2}`;
  };

  function validaEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function isAllSameDigits(phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/[^0-9]+/g, "");

    // Cria uma expressão regular que verifica se todos os dígitos são iguais
    const regex = /^(\d)\1*$/;

    // Testa o número limpo contra a expressão regular
    return regex.test(cleanedNumber);
  }

  const schema1 = yup.object().shape({
    nome: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, "Por favor, digite apenas letras e acentos")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado")
      .test("sobrenome", "Por favor, digite seu sobrenome", function (value) {
        if (!value) return false;
        const partes = value.trim().split(" ");
        if (partes.length < 2) return false; // Menos de duas partes (nome e sobrenome)
        return /[a-zA-Z]/.test(partes[1]); // Verifica se o segundo elemento (sobrenome) contém letras
      })
      .required("O nome é obrigatório"),

    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),

    email: yup
      .string()
      .required("Email é obrigatório")
      .email("Formato de e-mail inválido")
      .test("formato-email", "Formato de e-mail inválido", (value) => {
        return validaEmail(value);
      }),

    confirma_email: yup
      .string()
      .required("Confirmação do email é obrigatório")
      .email("Formato de e-mail inválido")
      .test(
        "formato-confirmacao-email",
        "Formato de e-mail inválido",
        (value) => {
          return validaEmail(value);
        }
      )
      .oneOf([yup.ref("email")], "Os emails devem ser iguais."),

    celular: yup
      .string()
      .test(
        "celular",
        "O Celular deve conter exatamente 11 dígitos",
        (value) => {
          if (value) {
            const Regex = /^\(\d{2}\) \d{5}-\d{4}$/;
            return Regex.test(value);
          }
          return false;
        }
      )
      .test("celular-invalido", "Informe um celular válido", (value) => {
        if (value) {
          const Regex = /^\(\d{2}\) \d{5}-\d{4}$/;
          const teste = Regex.test(value);
          if (teste) {
            if (isAllSameDigits(value)) {
              return false;
            } else {
              return true;
            }
          }
          return false;
        }
        return false;
      }),
  });

  const schema2 = yup.object().shape({
    senha: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*\._])[a-zA-Z\d!@#$&*\._]{8,}$/,
        "Verifique se sua senha cumpre os requisitos mínimos."
      )
      .test(
        "A senha não pode conter caracteres proibidos.",
        (value) => !/[/\\'`\-\"%(),]/.test(value)
      )
      .required("A senha é obrigatória."),
    confirma_senha: yup
      .string()
      .oneOf([yup.ref("senha")], "As senhas devem ser iguais.")
      .test(
        "A senha não pode conter caracteres proibidos.",
        (value) => !/[/\\'`\-\"%(),]/.test(value)
      )
      .required("A confirmação de senha é obrigatória."),
  });

  useEffect(() => {
    const etapa_1 = sessionStorage.getItem("etapa_1");
    const etapa_2 = sessionStorage.getItem("etapa_2");
    const etapa_3 = sessionStorage.getItem("etapa_3");
    let defaultStep = 1;

    if (etapa_1 === "true") {
      defaultStep = 2;
    }
    if (etapa_2 === "true") {
      defaultStep = 3;
    }
    if (etapa_1 === "true" && etapa_2 === "true" && etapa_3 === "true") {
      defaultStep = 3;
    }
    setStep(defaultStep);
  }, []);

  const handleSubmit = async (values) => {
    if (step === 1) {
      setIsLoading1(true);

      // Validação do e-mail (se eles são iguais)
      if (values.email !== values.confirma_email) {
        let optionsToast = {
          code: "102",
          message: "Os emails devem ser iguais.",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading1(false);
        return;
      }

      try {
        const response = await apiUser.verificaCadastroExistente(
          values.cpf,
          values.email,
          values.celular,
          tipo
        );

        if (response.status === true) {
          setIsLoading1(false);
        } else {
          const { mensagem, data } = response;

          let optionsToast = {
            code: mensagem.codigo,
            message: mensagem.mensagem,
            visible: true,
          };

          if (data.email) {
            console.log("Erro no campo email:", data.email[0]);
          }
          if (data.celular) {
            console.log("Erro no campo celular:", data.celular[0]);
          }
          if (data.tipo) {
            console.log("Erro no campo tipo:", data.tipo[0]);
          }

          setOptionsToast(optionsToast);
          setIsLoading1(false);
          return false;
        }
      } catch (error) {
        setIsLoading1(false);
        console.log(error);
        return false;
      }
    }

    if (step === 2) {
      setIsLoading2(true);

      if (aceitoTermos === null || aceitoTermos === false) {
        let optionsToast = {
          code: "102",
          message: "Por favor, aceite os termos de uso",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading2(false);
        return;
      } else {
        const validaCadastro = await cadastroCliente(values);

        if (!validaCadastro) {
          return false;
        }

        setIsLoading2(false);
      }
    }

    if (step === 3) {
      setIsLoading3(true);

      if (!codigoCompleto) {
        let optionsToast = {
          code: "102",
          message: "Por favor, digite o código que enviamos.",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading3(false);
        return;
      }

      try {
        const responseValidacao = await api.postValidaCodigoVerificador(
          user.cli_id,
          codigoCompleto
        );

        let optionsToast = {
          code: responseValidacao.mensagem.codigo,
          message: responseValidacao.mensagem.mensagem,
          visible: true,
        };

        setOptionsToast(optionsToast);

        if (responseValidacao.status === true) {
          setIsLoading3(false);
          setPercent3(100);
          setTimeout(() => {
            navigate("/Login/cli");
          }, 1800);
        } else {
          setIsLoading3(false);
          return false;
        }
      } catch (error) {
        console.log(error);
        setIsLoading3(false);
        return false;
      }

      setIsLoading3(false);
      return;
    }
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setTimeout(() => {
      setStep((prevStep) => prevStep + 1);
    }, 1000);
    setValidated(true);
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const backStep = (event) => {
    setStep((prevStep) => prevStep - 1);
  };

  function resetValidations() {
    setValidation({
      hasMinimumLength: false,
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasSpecialCharacter: false,
    });
  }

  useEffect(() => {
    if (step === 0) {
      navigate("/");
    }

    if (step === 1) {
      resetValidations();
    }
  }, [step]);

  async function cadastroCliente(valuesCadastro) {
    setIsLoading2(true);

    try {
      const response = await api.postCadastrar(
        formValues.nome,
        formValues.email,
        formValues.celular,
        formValues.cpf,
        valuesCadastro.senha,
        valuesCadastro.confirma_senha
      );

      // console.log(response.data);

      if (response.status === true) {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };

        setOptionsToast(optionsToast);
        setUser(response.data);
        return true;
      } else {
        setIsLoading2(false);
        return false;
      }
    } catch (error) {
      setIsLoading2(false);
      console.log(error);
      return false;
    }
  }

  async function enviaCodigoValidacao() {
    const response = await api.postEnviaCodigoValidacao();

    if (response.status === true) {
      setIsLoading2(false);
    }
  }

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState(eyeOff);

  const handleToggle2 = () => {
    if (type2 === "password") {
      setIcon2(eye);
      setType2("text");
    } else {
      setIcon2(eyeOff);
      setType2("password");
    }
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={(values) => {
              setDefaultNome(values.nome);
              setDefaultEmail(values.email);
              setDefaultCelular(values.celular);
              setDefaultConfirmaEmail(values.confirma_email);
              setDefaultCpf(values.cpf);

              const { celular, cpf, ...rest } = values;
              const celularSemMascara = functions.removeMascara(celular);
              const cpfSemMascara = functions.removeMascara(cpf);
              const validarCPF = functions.validarCPF(cpfSemMascara);
              const valuesSemMascara = {
                ...rest,
                celular: celularSemMascara,
                cpf: cpfSemMascara,
              };
              handleSubmit(valuesSemMascara);
            }}
            initialValues={{
              nome: defaultNome,
              email: defaultEmail,
              celular: defaultCelular,
              confirma_email: defaultConfirmaEmail,
              cpf: defaultCpf,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="nome" className="mt-3">
                  <FloatingLabel label={"Nome e sobrenome"}>
                    <InputMask
                      value={values.nome}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isFieldsDisabled}
                    >
                      {(inputProps) => (
                        <Form.Control
                          maxLength={80}
                          className={`input-register-login-facj ${
                            touched.nome && errors.nome ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="nome"
                          value={values.nome}
                          onChange={handleChange}
                          isValid={touched.nome && !errors.nome}
                          placeholder={"Nome e sobrenome"}
                          disabled={isFieldsDisabled}
                          {...inputProps}
                        />
                      )}
                    </InputMask>

                    {touched.nome && errors.nome && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.nome}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onBlur={handleBlur}
                      disabled={isFieldsDisabled}
                      onChange={(e) => {
                        handleChange(e);
                        const unmaskedCpf = functions.removeMascara(
                          e.target.value
                        );
                        handleCpfChangeDebounced(unmaskedCpf, setFieldValue);
                      }}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          value={values.cpf}
                          type="text"
                          name="cpf"
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          disabled={isFieldsDisabled}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                  <FloatingLabel label={"Email"}>
                    <InputMask
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isFieldsDisabled}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          placeholder="E-mail"
                          disabled={isFieldsDisabled}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.email && errors.email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirma_email">
                  <FloatingLabel label={"Confirme seu email"}>
                    <InputMask
                      value={values.confirma_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isFieldsDisabled}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.confirma_email && errors.confirma_email
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="confirma_email"
                          value={values.confirma_email}
                          onChange={handleChange}
                          isValid={
                            touched.confirma_email && !errors.confirma_email
                          }
                          placeholder="E-mail"
                          disabled={isFieldsDisabled}
                          {...inputProps}
                        />
                      )}
                    </InputMask>

                    {touched.confirma_email && errors.confirma_email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="celular">
                  <FloatingLabel label={"Celular"}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.celular}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isFieldsDisabled}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.celular && errors.celular
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="celular"
                          value={values.celular}
                          onChange={handleChange}
                          isValid={touched.celular && !errors.celular}
                          placeholder="Celular"
                          disabled={isFieldsDisabled}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.celular && errors.celular && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.celular}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <ParagraphSmall
                    textSize="13px"
                    text="Nos próximos passos enviaremos um SMS com um código
                    para confirmar seu número de celular."
                  />
                </Form.Group>

                <Row>
                  {isPreCadastro == true && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#EEB20A",
                          width: "12px",
                          height: "45px",
                          borderRadius: "25px",
                        }}
                      />
                      <ParagraphSmall
                        className=""
                        textSize="13px"
                        text="Prezado servidor, caso o número de celular ou e-mail esteja incorreto, entre em contato com a central de atendimento 
                        do aplicativo da sua processadora."
                      />
                    </div>
                  )}
                </Row>

                <Col className="mt-3 mb-3">
                  <ProgressBar
                    now={20}
                    label={`${20}%`}
                    animated={preCadastroLoading ? true : false}
                  />
                </Col>

                <Col className="d-lg-flex">
                  <Col className="d-flex justify-content-start">
                    <Button
                      type={"terciary"}
                      isSumbit={false}
                      text="Voltar"
                      onTouchable={backStep}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      type={"secondary-load"}
                      text="Próximo"
                      isSumbit={true}
                      isLoading={preCadastroLoading}
                      disabled={preCadastroLoading ? true : false}
                    />
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        );

      case 2:
        return (
          <Formik
            key={step}
            validationSchema={schema2}
            onSubmit={(values) => handleSubmit(values)}
            initialValues={{
              senha: "",
              confirma_senha: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              onFocus,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="senha">
                  <FloatingLabel label={"Senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={(event) => {
                        handlePasswordChange(event);
                        handleChange(event);
                      }}
                      onFocus={() => setShowDialogSenha(true)}
                      onBlur={() => setShowDialogSenha(false)}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>

                    {showDialogSenha && (
                      <Form.Control.Feedback
                        tooltip
                        className="d-block bg-white p-4 border border-1 bg-border-line shadow-sm mt-3"
                        style={{ zIndex: 9999 }}
                      >
                        <h3 className="fw-semibold fs-6 text-black">
                          A senha deve incluir pelo menos:
                        </h3>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validation.hasMinimumLength
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validation.hasMinimumLength
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validation.hasMinimumLength
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            8 caracteres
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validation.hasNumber ? ic_check_circle : ic_cancel
                            }
                            style={{
                              color: validation.hasNumber ? "#00b400" : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validation.hasNumber ? "#00b400" : "#f00",
                            }}
                          >
                            1 número
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validation.hasUpperCase
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validation.hasUpperCase
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validation.hasUpperCase
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 letra MAIÚSCULA
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validation.hasLowerCase
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validation.hasLowerCase
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validation.hasLowerCase
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 letra minúscula
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validation.hasSpecialCharacter
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validation.hasSpecialCharacter
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validation.hasSpecialCharacter
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 caractere especial (! @ # $ & * . _)
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              !validation.doesntHasProhibitedCharacter
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: !validation.doesntHasProhibitedCharacter
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: !validation.doesntHasProhibitedCharacter
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            Não contém caracteres proibidos
                          </p>
                        </div>
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirma_senha">
                  <FloatingLabel label={"Confirme sua senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.confirma_senha && errors.confirma_senha
                          ? "is-invalid"
                          : ""
                      }`}
                      type={type2}
                      name="confirma_senha"
                      value={values.confirma_senha}
                      onChange={(event) => {
                        handlePasswordChange(event);
                        handleChange(event);
                      }}
                      onFocus={() => setShowDialogConfirmaSenha(true)}
                      onBlur={() => setShowDialogConfirmaSenha(false)}
                      isValid={touched.confirma_senha && !errors.confirma_senha}
                      placeholder="Confirme sua senha"
                    />
                    {touched.confirma_senha && errors.confirma_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_senha}
                      </Form.Control.Feedback>
                    )}
                    <span
                      className="icon-password-facj"
                      onClick={handleToggle2}
                    >
                      <Icon icon={icon2} size={25} />
                    </span>

                    {showDialogConfirmaSenha && (
                      <Form.Control.Feedback
                        tooltip
                        className="d-block bg-white p-4 border border-1 bg-border-line shadow-sm mt-3"
                        style={{ zIndex: 9999 }}
                      >
                        <h3 className="fw-semibold fs-6 text-black">
                          A senha deve incluir pelo menos:
                        </h3>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validationConfirmaSenha.hasMinimumLength
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validationConfirmaSenha.hasMinimumLength
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validationConfirmaSenha.hasMinimumLength
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            8 caracteres
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validationConfirmaSenha.hasNumber
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validationConfirmaSenha.hasNumber
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validationConfirmaSenha.hasNumber
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 número
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validationConfirmaSenha.hasUpperCase
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validationConfirmaSenha.hasUpperCase
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validationConfirmaSenha.hasUpperCase
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 letra MAIÚSCULA
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validationConfirmaSenha.hasLowerCase
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validationConfirmaSenha.hasLowerCase
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validationConfirmaSenha.hasLowerCase
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 letra minúscula
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              validationConfirmaSenha.hasSpecialCharacter
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color: validationConfirmaSenha.hasSpecialCharacter
                                ? "#00b400"
                                : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color: validationConfirmaSenha.hasSpecialCharacter
                                ? "#00b400"
                                : "#f00",
                            }}
                          >
                            1 caractere especial (! @ # $ & * . _)
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon
                            icon={
                              !validationConfirmaSenha.doesntHasProhibitedCharacter
                                ? ic_check_circle
                                : ic_cancel
                            }
                            style={{
                              color:
                                !validationConfirmaSenha.doesntHasProhibitedCharacter
                                  ? "#00b400"
                                  : "#f00",
                              margin: 0,
                            }}
                          />
                          <p
                            className="fw-medium"
                            style={{
                              color:
                                !validationConfirmaSenha.doesntHasProhibitedCharacter
                                  ? "#00b400"
                                  : "#f00",
                            }}
                          >
                            Não contém caracteres proibidos
                          </p>
                        </div>
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
                <ParagraphSmall
                  textSize="13px"
                  text="A senha deve conter pelo menos 8 caracteres, um número, uma letra maiúscula, uma letra
                  minúscula e um caractere especial"
                />

                <Row>
                  <Col className="d-flex mt-2 align-items-center">
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      checked={aceitoTermos}
                      onChange={(e) => setAceitoTermos(e.target.checked)}
                    />
                    <Col>
                      <span>Li e concordo com os </span>
                      <span
                        className="link-color"
                        style={{ cursor: "pointer" }}
                        onClick={handleTermosClick}
                      >
                        <strong>Termos de uso</strong>
                      </span>
                      <span> e a </span>
                      <span
                        className="link-color"
                        style={{ cursor: "pointer" }}
                        onClick={handlePoliticaClick}
                      >
                        <strong>Política de Privacidade.</strong>
                      </span>
                    </Col>
                  </Col>
                </Row>

                {modalShow && (
                  <TermosDeUsoModal
                    size="lg"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                )}
                {modalPoliticaShow && (
                  <PoliticaPrivacidadeModal
                    size="lg"
                    show={modalPoliticaShow}
                    onHide={() => setModalPoliticaShow(false)}
                  />
                )}

                <Col className="">
                  <ProgressBar
                    now={40}
                    label={`${40}%`}
                    animated={isLoading2 ? true : false}
                  />
                </Col>

                <Col className="d-lg-flex">
                  <Col className="d-flex justify-content-start">
                    <Button
                      type={"terciary"}
                      text="Voltar"
                      onTouchable={() => {
                        voltarStep1();
                      }}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      type={"secondary-load"}
                      text="Cadastrar"
                      isSumbit={true}
                      isLoading={isLoading2}
                      disabled={isLoading2 ? true : false}
                    />
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        );

      case 3:
        return (
          <Container className="alinhamento-step ">
            <Col className="d-flex justify-content-center">
              <Col className="text-center mb-3">
                <Subtitle text="Por fim, precisamos validar sua conta!" />
                <Col className="d-flex justify-content-center">
                  <ParagraphSmall
                    text="Por favor, digite abaixo o código de verificação 
            enviado ao seu celular via SMS e/ou E-mail cadastrado."
                  />
                </Col>
              </Col>
            </Col>

            <Row className="d-flex flex-column">
              <Col>
                <Formik
                  initialValues={{
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                  }}
                  onSubmit={() => handleSubmit(codigoCompleto)}
                >
                  {({ values, handleChange }) => (
                    <Form className="" noValidate>
                      <Col className="d-flex gap-3 ">
                        <Field
                          key={1}
                          innerRef={(el) => (inputRefs.code1 = el)}
                          ref={inputRefs.code1}
                          type="text"
                          name="code1"
                          value={values.code1}
                          onChange={(e) => {
                            handleChange(e);
                            setCode1(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            console.log(e);
                            inputRefs.code2.focus();
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={2}
                          innerRef={(el) => (inputRefs.code2 = el)}
                          ref={inputRefs.code2}
                          type="text"
                          name="code2"
                          value={values.code2}
                          onChange={(e) => {
                            handleChange(e);
                            setCode2(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={3}
                          innerRef={(el) => (inputRefs.code3 = el)}
                          ref={inputRefs.code3}
                          type="text"
                          name="code3"
                          value={values.code3}
                          onChange={(e) => {
                            handleChange(e);
                            setCode3(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={4}
                          innerRef={(el) => (inputRefs.code4 = el)}
                          ref={inputRefs.code4}
                          type="text"
                          name="code4"
                          value={values.code4}
                          onChange={(e) => {
                            handleChange(e);
                            setCode4(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col className="d-flex justify-content-center">
                <Col className="d-flex flex-column gap-2 align-items-center justify-content-center">
                  <Col>
                    <CountdownTimer initialTime={240} cliId={user.cli_id} />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row className="d-flex flex-column gap-3 mb-3">
              <Col>
                <ProgressBar
                  now={percent3}
                  label={`${percent3}%`}
                  animated={isLoading3}
                />
              </Col>
              <Col>
                <ParagraphSmall
                  textAlign="center"
                  textSize="13px"
                  text="Em caso de dúvidas entre em contato com o 
               <strong><a  target=blank href='https://api.whatsapp.com/send?phone=554199934379&text=Ol%C3%A1,%20vi%20seu%20contato%20no%20site%20e%20gostaria%20de%20saber%20mais.'>Atendimento</a>.</strong>"
                />
              </Col>
              <Col className="d-lg-flex gap-2 justify-content-between">
                <Button
                  type={"terciary"}
                  isSumbit={false}
                  text="Sair"
                  onTouchable={handleSair}
                />
                <Button
                  type={"secondary-load"}
                  text="Próximo"
                  onTouchable={handleSubmit}
                  isLoading={isLoading3}
                  disabled={isLoading3 ? true : false}
                />
              </Col>
            </Row>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="container-register-facj">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        duration={optionsToast.duration}
        setDefaultOptions={() => {
          fecharToast();
          if (optionsToast?.onAction) {
            optionsToast?.onAction();
          }
        }}
      />
      <Row>
        <Col>
          <Subtitle text={"Crie sua conta"} />
          {renderForm()}
        </Col>
      </Row>
    </Container>
  );
};
