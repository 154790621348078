import React, { useEffect, useState, useCallback } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import {
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
  FormControl,
} from "react-bootstrap";
import Btn from "../../atoms/Button";
import InputMask from "react-input-mask";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";
import { Toasts } from "../../utils/Toasts";
import { Navigate, useNavigate } from "react-router";
import Paragraph from "../../atoms/Paragraph";

export const AdvDadosConsulta = ({ data }) => {
  const api = new PerfilService();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/FinanceiroAdv");
  };

  const [defaultValorConsulta, setDefaultValorConsulta] = useState("");
  const [defaultTempoConsulta, setDefaultTempoConsulta] = useState("");

  const [controlForm, setControlForm] = useState(false);

  const id = sessionStorage.getItem("id");

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  useEffect(() => {
    setDefaultValorConsulta(data.data.dadosAdvogado.valorConsulta);
    setDefaultTempoConsulta(data.data.dadosAdvogado.tempoConsulta);
  }, [data]);

  useEffect(() => {
    if (defaultValorConsulta != null || defaultTempoConsulta != null) {
      setControlForm(true);
    }
  }, [defaultValorConsulta, defaultTempoConsulta]);

  const Schema1 = yup.object().shape({
    valor: yup.number().required("O valor da consulta é obrigatório."), //.min(3, 'Mínimo de caracteres não detectado'),
    // tempo: yup
    //   .number()
    //   .required('O tempo de consulta é obrigatório.')
  });

  const handleSubmit = (values) => {
    api
      .postAlteraDadosConsulta(
        values.valor,
        1 // Valor fixo
      )
      .then(data);

    let optionsToast = {
      code: data.mensagem.codigo,
      message: data.mensagem.mensagem,
      visible: true,
    };
    setOptionsToast(optionsToast);
  };

  const renderForm = () => {
    return (
      <Container className="mt-1 p-0">
        <Formik
          initialValues={{
            valor: defaultValorConsulta,
            tempo: defaultTempoConsulta,
          }}
          validationSchema={Schema1}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="d-flex flex-column">
                <Col lg={4} className="pb-3">
                  <FloatingLabel controlId="valor" label="Digite o valor:">
                    <Field
                      type="number"
                      id="valor"
                      name="valor"
                      className={`form-control ${
                        touched.valor && errors.valor ? "is-invalid" : ""
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.valor}
                    />
                    {touched.valor && errors.valor && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.valor}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.valor}
                  </Form.Control.Feedback>
                  <Col className="pt-3">
                    <Btn
                      type={"secondary-sm"}
                      text="Salvar Alterações"
                      isSumbit={true}
                    />
                  </Col>
                  <Col lg={4} className="pb-3"></Col>

                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.TempoConsulta}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={4}></Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    );
  };

  return (
    <Container className="">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      {controlForm == false ? (
        <div></div>
      ) : (
        <Row>
          <Col>{renderForm()}</Col>
        </Row>
      )}
    </Container>
  );
};
