import api from "../Api";
 
export class LoginService {
  
    async getAll() { 
    return await api.get("/", {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async postLogin(
    cpf,
    senha
  ) {
    return await api.post("/adm/usuario/login", {
      "cpf": cpf,
      "senha": senha
    }, {
      headers: {
        "Content-type": "application/json",
        // Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
  }

};
