import React, { useState } from "react";
import "./styles.css";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import { AgendaService } from "../../../services/AdvogadoServices/AgendaService.js";
import { Toasts } from "../../utils/Toasts/index.js";


const ExcluirHorarioModal = ({ data, horaInicio, horaFim, showModal, handleCloseModal }) => {
  const apiAgenda = new AgendaService();
  
  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  const handleExcluir = () => {
    apiAgenda.excluirHorarioDisponibilidade(data, horaInicio, horaFim)
      .then((data) => {
        if (data.status) {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);

          setTimeout(() => {
            setTimeout(() => {
              window.location.reload();
            }, 10);
          }, 2000);
         
        } else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
        <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() =>
          setOptionsToast({ code: "", message: "", visible: false })
        }
      />
      <Modal.Header closeButton>
        <Modal.Title>Confirmação de Exclusão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Paragraph
          text={`Tem certeza de que deseja excluir este horário da sua agenda?`}
        />
      </Modal.Body>
      <Modal.Footer>
        <Btn type="terciary-sm" text="Voltar" onTouchable={handleCloseModal} />
        <Btn type="danger-sm" text="Sim, excluir!" onTouchable={handleExcluir} /> 
      </Modal.Footer>
    </Modal>
  );
};

export default ExcluirHorarioModal;