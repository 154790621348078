import React from "react";
import './styles.css';
import { useParams } from "react-router";
import { HomeTemp } from "../../components/templates/HomeTemp";

import { HomeAdvTemp } from "../../components/templates/HomeAdvTemp";
import { HomeCliTemp } from "../../components/templates/HomeCliTemp";

import { NotFound } from "../NotFound";



export const Home = () => {

  const isUserLoggedIn = sessionStorage.getItem('logado') ?? false;
  const userType = sessionStorage.getItem('tipo') ?? 'c' ;

  function getInfoFromLoginSession() {
    const preAgendamentoLogin = sessionStorage.getItem('preAgendamentoLogin');
    if (preAgendamentoLogin) {
      const [logedFromAgendamento, advId] = preAgendamentoLogin.split('#');
      return { logedFromAgendamento: logedFromAgendamento === 'true', advId };
    }
    return { logedFromAgendamento: false, advId: null };
  }
  
  const sessionInfo = getInfoFromLoginSession();

  if(sessionInfo.logedFromAgendamento == true) {
     let advId = sessionInfo.advId
 //    sendToPreAgendamentoPage(advId);

  }

   const renderTemp = () => {
    if(isUserLoggedIn) {
      switch (userType.toLowerCase()) {
        case "a":
          return <HomeAdvTemp />;
        case "c":
          return <HomeCliTemp />;
        default:
          <HomeTemp />
      }
    } else {
      return <HomeTemp />;
    }
    };


  

    return (
         renderTemp()
    );

}