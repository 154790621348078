import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ParagraphSmall from "../../atoms/ParagraphSmall/index.js";
import Paragraph from "../../atoms/Paragraph/index.js";
import { Row, Col, Container } from "react-bootstrap";
import Icons from "../../atoms/Icons/index.js";
import { useState } from "react";
import ExcluirHorarioModal from "../ExcluirHorarioModal/index.js";
import ReativarHorarioModal from "../ReativarHorarioModal/index.js";

const HorarioDisponivelCard = ({
  data,
  horaInicio,
  horaFim,
  indisponivel,
  showIcon,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showExcluirModal, setShowExcluirModal] = useState(false);
  const [showReativarModal, setShowReativarModal] = useState(false);
  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  const handleExcluir = () => {
    setShowExcluirModal(true);
  };

  const handleReativar = () => {
    setShowReativarModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container className="d-flex justify-items-center gap-2">
      <Col
        lg={12}
        className={`border-start border-2 shadow ps-3 p-1 bg-light rounded d-flex align-items-center gap-2 ${indisponivel ? "border-danger" : ""}`}
      >
        <Col lg={6} className="d-flex align-items-center flex-row gap-3">
          <Paragraph text={data} className="fw-bold" />
          <Col
            lg={4}
            className="border border-primary pt-1 px-2 rounded d-flex justify-content-center"
          >
            <Paragraph text={`${horaInicio}`} className="fw-bold" />
          </Col>
        </Col>
        <Col lg={6} className="pe-1 gap-2 d-flex flex-row align-items-center">
          {indisponivel
            ? showIcon && (
                <Row className="gap-2 align-items-center">
                  <Col lg={2} className="cursor" onClick={handleReativar}>
                    <Icons type="desfazer" />
                  </Col>
                  <Col>
                    <ParagraphSmall text="Reativar horário" />
                  </Col>
                </Row>
              )
            : showIcon && (
                <Col lg={2} className="cursor" onClick={handleExcluir}>
                  <Icons type="excluir" />
                </Col>
              )}
          {!indisponivel && <ParagraphSmall text="Excluir horário" />}
        </Col>
      </Col>
      <ExcluirHorarioModal
        data={data}
        horaInicio={horaInicio}
        horaFim={horaFim}
        showModal={showExcluirModal}
        handleCloseModal={handleCloseModal}
      />
      <ReativarHorarioModal
        data={data}
        horaInicio={horaInicio}
        horaFim={horaFim}
        showModal={showReativarModal}
        handleCloseModal={handleCloseModal}
      />
    </Container>
  );
};

export default HorarioDisponivelCard;
