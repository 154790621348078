import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import AdmSiteListaConsultas from "../../molecules/AdmSiteListaConsultas";

const AdmSiteConsultas = () => {
  return (
    <Container className="p-4">
      <Row className="">
        <Col lg={2} className="m-0 p-0  text-start">
          <Breadcrumb className="">
            <Breadcrumb.Item>Gerencial</Breadcrumb.Item>
            <Breadcrumb.Item href="#" active>
              Consultas
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center ">
          <Col className="">
            <Subtitle text="Consultas" />
          </Col>
        </Col>
      </Row>

      <Row>
        <Col>
          <AdmSiteListaConsultas />
        </Col>
      </Row>
    </Container>
  );
};

export default AdmSiteConsultas;
