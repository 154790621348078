import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import Btn from "../../atoms/Button";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { ForumService } from "../../../services/AdvogadoServices/ForumService";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { format } from "date-fns";
import DropdownBtn from "../../atoms/DropdownBtn";
import Paragraph from "../../atoms/Paragraph";

const nomeUsuario = sessionStorage.getItem("nome") || "";
const primeiraLetra = nomeUsuario.charAt(0);

const ChatAdvogado = () => {
  const api = new ForumService();

  const [showMessageInput, setShowMessageInput] = useState(false);
  const [clienteSelecionadoId, setClienteSelecionadoId] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [dados, setDados] = useState(null);
  const [clienteData, setClienteData] = useState(null);
  const [clientesArray, setClientesArray] = useState([]);
  const cacCliId = sessionStorage.getItem("id");
  const [mensagens, setMensagens] = useState([]);
  const [mensagemCliente, setMensagemCliente] = useState("");
  const [clienteId, setClienteId] = useState("");
  const [hasNewMessage, setHasNewMessage] = useState(false);

  const [nomes, setNomes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.chatAdvogado();
      try {
        if (response.status === true) {
          // console.log("responde da json: " + response.data)
          let clienteArray = response.data;

          setClientesArray(clienteArray);

          // const ids = clientesArray.map((cliente) => cliente.cliId);
          const nomes = clienteArray.map((cliente) => cliente.nome_cliente);
          const fotosPerfil = clientesArray.map(
            (cliente) => cliente.fotoPerfilCliente
          );
        } else {
          console.error("Erro na resposta da API:", response.message);
        }
      } catch (error) {
        console.error("Erro na chamada da API:", error);
      }
    };

    fetchData();
  }, []);

  const handleAvatarClick = async (cli, adv) => {
    if (!cli || !adv) {
      console.error("cli ou adv está vazio");
      return;
    }

    try {
      setClienteId(cli);

      const response = await api.mensagemAdvogado(cli);

      setClienteId(cli);
      setClienteSelecionadoId(cli);

      if (response.status === true && response.data.length > 0) {
        setHasNewMessage(true);
        const values = {
          id: response.data.cac_id,
          mensagem: response.data.cac_mensagem,
          data: response.data.cac_data,
          cliente: response.data.cac_enviado_pelo_cliente
        };

        // Atualize o estado 'mensagens' com as mensagens retornadas pela API
        setMensagens(response.data);
      } else {

        if (response.codigo === "102") {
          console.log("Não existem dados cadastrados para este advogado.");
        }

        // Caso não haja mensagens, limpe o estado 'mensagens'
        setMensagens([]);
      }
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  const salvarMensagemCliente = () => {
    const newMessage = {
      cac_id: cacCliId,
      cac_mensagem: mensagemCliente,
      cac_data: new Date(),
      cac_enviado_pelo_cliente: false,
    };


    api
      .salvarNovaMensagemCliente(clienteId, mensagemCliente)
      .then((data) => {
        setMensagens((mensagensAnteriores) => [
          ...mensagensAnteriores,
          newMessage,
        ]);
        setMensagemCliente("");

      })
      .catch((error) => {
        console.log("Erro ao salvar mensagem:", error);
      });
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-list");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  useEffect(() => {
    // Rolando o chat para a base 100% após o carregamento inicial
    scrollToBottom();
  }, [mensagens]); // Forçar a rolagem sempre que a lista de mensagens for atualizada

  const [filtroTermo, setFiltroTermo] = useState("");
  const handleFiltroChange = (event) => {
    setFiltroTermo(event.target.value);
  };

  const clienteFiltrados = clientesArray.filter((cliente) =>
    cliente.nome_cliente.toLowerCase().includes(filtroTermo.toLowerCase())
  );

  const formatDate = (date) => {
    if (typeof date === 'object') {
      date = date.toISOString();
    }
    if (date && date.split) {
      let dateStr = date.split("T")[0];
      const dataToFormat = new Date();
      dataToFormat.setDate(dateStr.split('-')[2]);
      dataToFormat.setMonth(parseInt(dateStr.split('-')[1]) - 1);
      dataToFormat.setFullYear(dateStr.split('-')[0]);
      return format(dataToFormat, "dd/MM/yyyy");
    } else{ 
      return date;
    }
  }


  return (
    <Container className="advogado-chat">
      <Col>
        <Paragraph text="Os advogados que contribuírem com respostas no nosso fórum serão
        recompensados com destaque na lista de busca do site. Isso significa que, quanto mais
        você ajudar a esclarecer dúvidas legais, mais visível você estará para os usuários
        que procuram assistência jurídica!"/>
      </Col>
      <Row>
        <Col lg={4} className="mt-3">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Filtrar por nome"
              value={filtroTermo}
              onChange={handleFiltroChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="">
        <Col
          lg={2}
          className="m-0 border border-2 rounded-start  p-0 text-center gap-2 d-flex row justify-content-center align-items-center advogados-list"
        >
          {clienteFiltrados.map((cliente, index) => (
            <Col
              key={index}
              className={`clickable-avatar ${cliente.cliId === clienteSelecionadoId
                ? "selected-advogado"
                : ""
                }`}
              onClick={() => handleAvatarClick(cliente.cliId, cacCliId)}
            >
              <AvatarPerfil
                type="C"
                base64={cliente.fotoPerfilCliente}
                width="4rem"
                height="4rem"
                padding="0.1rem"
                margin="1px"
                display="none"
              />
              <Col className="d-flex justify-content-center">
                <ParagraphSmall text={cliente.nome_cliente} />
              </Col>
            </Col>
          ))}
        </Col>

        <Col lg={10} className="bg-chat rounded-end">
          <Col className="chat-list  p-3">
            <Row className="">
              {mensagens.map((mensagem, index) => (
                <div key={index} className="">
                  <div
                    className={
                      mensagem.cac_enviado_pelo_cliente
                        ? "mensagem-cliente"
                        : "mensagem-advogado"
                    }
                  >
                    {mensagem.cac_mensagem}
                  </div>
                  <div
                    className={`data ${mensagem.cac_enviado_pelo_cliente
                      ? "data-cliente"
                      : "data-advogado"
                      }`}
                  >
                    {formatDate(mensagem.cac_data)}
                  </div>
                </div>
              ))}
            </Row>
          </Col>

          <Col className=" d-flex row gap-2 align-items-end-center ">
            <Col lg={1} className="d-flex">
              <AvatarPerfil
                type="B"
                base64={sessionStorage.getItem("fotoPerfil")}
                width="4rem"
                height="4rem"
                padding="0.1rem"
                margin="1px"
                display="none"
              />
            </Col>
            <Col lg={9} className="">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Digite uma nova mensagem"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Mensagem"
                  style={{ height: "5rem" }}
                  value={mensagemCliente}
                  onChange={(e) => setMensagemCliente(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col lg={1}>
              <Btn
                type={"secondary-sm"}
                isSumbit={false}
                text="Enviar"
                onTouchable={salvarMensagemCliente}
              />
            </Col>
          </Col>
          <Col className="d-flex justify-content-end"></Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatAdvogado;
