import functions from "../../components/utils/functions";
import api from "../Api";

export class AgendaService {
  async listarAgenda() {
    return await api
      .post(
        "/advogado/listar-agenda",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async buscarAgendaDia( // INUTILIZADO
    data
  ) {
    return await api
      .post(
        "/advogado/buscar-agenda-dia",
        {
          data: data
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async buscarDadosDia(
    data
  ) {
    return await api
      .post(
        "/advogado/buscar-dados-dia",
        {
          data: data
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async gerarAgenda( // INUTILIZADO
    daysAndHours,
    replicatePeriod
  ) {
    return await api
      .post(
        "/advogado/gerar-agenda",
        {
          daysAndHours: daysAndHours,
          replicatedTo: replicatePeriod
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async cancelarAgenda( // INUTILIZADO

  ) {
    return await api
      .post(
        "/advogado/cancelar-agenda",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async definirDisponibilidade(duracao, horarioAtendimento, aceitaTermos) {
    // criar-agenda
    return await api
      .post(
        "/advogado/definir-disponibilidade",
        {
          duracao: duracao,
          horarioAtendimento: horarioAtendimento,
          aceitaTermos: aceitaTermos,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async excluirDisponibilidade() {
    // excluir-agenda
    return await api
      .post(
        "/advogado/excluir-disponibilidade",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async excluirHorarioDisponibilidade(data, horaInicio, horaFim) {
    // excluir-horario
    return await api
      .post(
        "/advogado/excluir-horario-disponibilidade",
        {
          data: data,
          horaInicio: horaInicio,
          horaFim: horaFim
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async excluirIndisponibilidade(data, horaInicio, horaFim) {
    return await api
      .post(
        "/advogado/excluir-indisponibilidade",
        {
          data: data,
          horaInicio: horaInicio,
          horaFim: horaFim
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async removerTodasIndisponibilidades() {
    // excluir-agenda
    return await api
      .post(
        "/advogado/excluir-todas-indisponibilidades",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async buscarDisponibilidadeAdvogado() { // INUTILIZADO
    return await api
      .post(
        "/advogado/buscar-disponibilidade-advogado",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async gerarHorariosVagosDoDia(data) { // INUTILIZADO
    return await api
      .post(
        "/advogado/horarios-vagos-dia",
        {
          data: data,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }
}
