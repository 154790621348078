import functions from "../../components/utils/functions";
import api from "../Api";

export class PerfilService {
  async postPerfilCliente() {
    return await api
      .post(
        "/cliente/dados-pessoais-cli-perfil",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async postAlteraDadosPessoaisCliente(
    nome,
    celular,
    email
  ) {
    return await api
      .post(
        "/cliente/altera-dados-pessoais-cli",
        {
          nome: nome,
          celular: celular,
          email: email
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async postAlterarSenha(
    senha,
    nova_senha,
    confirma_senha
  ) {
    return await api
      .post(
        "/cliente/altera-senha",
        {
          senha: senha,
          nova_senha: nova_senha,
          confirma_senha: confirma_senha
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async adicionarFoto(foto) {
    let formData = new FormData();
    // formData.append("id", id);
    formData.append("foto", foto);

    return await api
      .post("/fotoUsuario/adicionar-foto", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.msg === "Token Expirado3."
        ) {
          functions.modalToken();
        }
      });
  }

  async cadastrarContaBanco(agencia, cep, codigoBanco, complemento, documento, contaCorrente, nome, numero) {
    try {
      const response = await api.post("/cliente/cadastrar-conta-banco", {
        "agencia": agencia,
        "cep": cep,
        "codigoBanco": codigoBanco,
        "complemento": complemento,
        "documento" : documento,
        "contaCorrente": contaCorrente,
        "nome": nome,
        "numero": numero,
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } else {
        throw error; 
      }
    }
  }

  
}
