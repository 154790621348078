import React, { useState, useEffect } from "react";
import { Col, Container, Row, Badge } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import Btn from "../../atoms/Button";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import { HomeService } from "../../../services/HomeServices/HomeService";
import AgendaAdvModal from "../../molecules/AgendaAdvModal";
import { AdvOpinioesModal } from "../AdvOpinioesModal";
import "./styles.css";

const CardResultadoBuscaAdv = ({ data }) => {
  const api = new HomeService();

  const [modalShow, setModalShow] = useState(false);
  const [modalOpiniaoShow, setModalOpiniaoShow] = useState(false);

  const [availableData, setAvailableData] = useState([]);
  const [advogadoId, setAdvogadoId] = useState(null);
  const [opinioesAdv, setOpinioesAdv] = useState();

  const resetModalState = () => {
    setAvailableData([]);
    setAdvogadoId(null);
  };

  const handleHorariosDisponiveis = (advogado_id) => {
    setAdvogadoId(advogado_id);
  };

  useEffect(() => {
    if (advogadoId !== null) {
      fetchAgendaData(advogadoId);
    }
  }, [advogadoId]);

  const fetchAgendaData = async (advogado_id) => {
    try {
      const response = await api.buscarAgendaAdv(data.advogado_id);
      if (response && response.data) {
        setAvailableData(response.data);
        setModalShow(true);
      } else {
        console.error("Erro ao buscar dados da agenda.");
        setModalShow(false);
      }
    } catch (error) {
      console.error("Erro na chamada de API:", error);
      setModalShow(false);
    }
  };

  const openModalOpinion = async () => {
    try {
      const response = await api.listarOpinioesAdv(data.advogado_id);
      // TODO-CRITICAL: listarOpinioesAdv na verdade lista os comentários (explicações) ao fazer agendamento PRIVADO da consulta!
      // COLUNAS MAL NOMEADAS!
      if (response && response.data) {
        setOpinioesAdv(response.data);
        setModalOpiniaoShow(true);
      } else {
        console.error("Erro ao buscar opiniões do advogado.");
        setModalOpiniaoShow(false);
      }
    } catch (error) {
      console.error("Erro na chamada de API:", error);
      setModalOpiniaoShow(false);
    }
  };

  return (
    <Container
      lg={12}
      className="box-adv-busca justify-content-center align-items-center"
    >
      <Row className="d-flex flex-column py-3 my-3 shadow-lg rounded d-flex align-items-center">
        <Col className="d-lg-flex align-items-center ms-lg-4">
          <Col sm={12} lg={2} className="text-center pe-2">
            <AvatarPerfil
              base64={data.foto}
              type="B"
              width="7rem"
              display="none"
              height="7rem"
              padding="0.4rem"
            />
          </Col>
          <Col sm={12} lg={5} className="ps-lg-4">
            <Col sm={12} className="p-0 text-center text-lg-start">
              <Badge className="me-2 p-2 badge-bg mb-3">
                {data.adv_cidade + "/" + data.adv_estado}
              </Badge>
              <Subtitle text={data.cliente_nome} />
              <Paragraph text={"OAB " + data.oab_numero} />
              <Col lg={6} className="d-flex align-items-center gap-2">
                <Paragraph text={data.numero_estrelas} />
                <Rating
                  readonly={true}
                  initialValue={data.numero_estrelas}
                  size={25}
                />
                <Col
                  className="cursor underline-link"
                  onClick={() => openModalOpinion()}
                >
                  <Paragraph text="Opiniões" isSubmit={true} />
                </Col>
              </Col>
            </Col>
          </Col>
          <Col sm={2} lg={2} className="text-center">
            <Col>
              <Subtitle text={`+ de ${data.quantidade_consultas}`} />
              <Paragraph text="Consultas" />
            </Col>
          </Col>
          <Col className="d-flex pt-2 align-items-center justify-content-center justify-content-lg-start">
            {data.possui_agenda == true ? (
              <Col
                lg={9}
                className="horarios-disp pt-2 justify-content-center justify-content-lg-start"
              >
                {data.plano_disponivel && (
                  <>
                    <Col className="plan-flag rounded p-2 text-center">
                      <Paragraph
                        textColor="#FFC107"
                        text="Consulta via plano"
                      ></Paragraph>
                    </Col>
                    <br />
                  </>
                )}
                <Btn
                  type={"secondary-sm"}
                  isSubmit={false}
                  text="Horários disponíveis"
                  onTouchable={() => {
                    handleHorariosDisponiveis(data.advogado_id);
                  }}
                />
              </Col>
            ) : (
              <Col lg={9} className="border rounded p-1 text-center">
                <Paragraph text="Sem horários disponíveis no momento."></Paragraph>
              </Col>
            )}
          </Col>
        </Col>
        <Row className="ms-2 ">
          <Col>
            <Col className=" d-flex align-items-center gap-2 justify-content-center">
              <Col className="">
                {data.area.map((area, index) => (
                  <Badge bg="secondary" className="m-1 p-2" key={index}>
                    {area}
                  </Badge>
                ))}
              </Col>
            </Col>
          </Col>
        </Row>

        <Col>
          {data.resumo ? (
            <>
              <Col className="text-start mx-lg-5">
                <Subtitle text="Resumo Profissional" />
              </Col>
              <Col className="text-start mx-lg-5">
                <Paragraph text={data.resumo} />
              </Col>
            </>
          ) : null}
        </Col>
      </Row>
      <AgendaAdvModal
        show={modalShow}
        onHide={() => {
          resetModalState();
          setModalShow(false);
        }}
        data={availableData}
        advId={advogadoId}
      />
      <AdvOpinioesModal
        data={data}
        show={modalOpiniaoShow}
        onHide={() => {
          resetModalState();
          setModalOpiniaoShow(false);
        }}
        opinioesAdv={opinioesAdv || null}
      />
    </Container>
  );
};

export default CardResultadoBuscaAdv;
