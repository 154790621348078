import {
  Container,
  Row,
  Col,
  Modal,
  Tabs,
  Tab,
  Form,
  Image,
} from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import { act, useEffect, useState } from "react";
import Subtitle from "../../atoms/Subtitle";
import CardConfirmarConsultaAdv from "../../molecules/CardConfirmarConsultaAdv";
import { Toasts } from "../../utils/Toasts";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { PagamentoConsignado } from "../../molecules/PagamentoConsignado";
import { PagamentoParticular } from "../../molecules/PagamentoParticular";
import { PagamentoPlano } from "../../molecules/PagamentoPlano";
import { useNavigate } from "react-router-dom";
import { SairAgendamentoModal } from "../../molecules/SairAgendamentoModal";
import "./styles.css";
import ImgBanner from "../../atoms/ImgBanner";
import image from "../../../../src/img/rectangle256.png";

export const AdvAgendamento = ({ advId, date, time }) => {
  const apiHome = new HomeService();
  const navigate = useNavigate();
  const id = sessionStorage.getItem("id");

  const [dados, setDados] = useState(null);
  const [dadosCard, setDadosCard] = useState(null);
  const [controlRender, setControlRender] = useState(false);
  const [valor, setValor] = useState("");
  const [comentario, setComentario] = useState("");
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const [showModal, setShowModal] = useState(false);

  const steps = [
    {
      nome: "Consulta",
      className: "ag-left",
    },
    {
      nome: "Pagamento",
      className: "ag-center",
    },
    {
      nome: "Resumo",
      className: "ag-right",
    },
  ];

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  const handleBack = () => {
    if (step == 0) {
      handleBackPage();
    } else setStep(step - 1);
  };

  const handleBackPage = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const changeStep = () => {
    if (step == 0 && !comentario) {
      let optionsToast = {
        code: "102",
        message: "Explique o seu caso antes de prosseguir para o pagamento.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    } else if (step == 1 && !activeTab) {
      return;
    } else setStep(step + 1);
  };

  const changeStepPlano = () => {
    setStep(2);
  };

  const meuPerfil = () => {
    navigate("/ConsultasCli");
  };

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const req = async () => {
    await apiHome
      .getInfosPreAgendamento(advId, date, time)
      .then((data) => {
        if (data.status === true) {
          setDados(data.data);
          let item = {
            advogado_id: data.data.advogado_id,
            valor_consulta: data.data.valor_consulta,
            cliente_nome: data.data.cliente_nome,
            oab_numero: data.data.oab_numero,
            numero_estrelas: data.data.numero_estrelas,
            resumo: data.data.resumo,
            data: data.data.data,
            foto: data.data.foto,
          };
          setValor(data.data.valor_consulta);
          setDadosCard(item);
          setControlRender(true);
        } else {
          // Handle the error message
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    req(); // Realiza a requisição ao carregar o componente
  }, [advId, date, time]);

  const [activeTab, setActiveTab] = useState("");

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const formattedDate = formatDate(date);

  const renderAgendamento = () => {
    return (
      <Container>
        <Row className="ag-steps">
          {steps.map((item, idx) => {
            return (
              <Col
                lg={4}
                sm={12}
                md={4}
                className={
                  "pt-2 " + (step >= idx ? "ag-active " : "") + item.className
                }
              >
                {(valor != 0 || idx != 1) && (
                  <div className="ag-steps-div">
                    <p>{item.nome}</p>
                  </div>
                )}
              </Col>
            );
          })}
        </Row>
        <Row className="d-block">
          {false && (
            <>
              <Col lg={12} className="text-center">
                <hr className="border-warning border-3 mb-5 mt-5" />
                <Paragraph
                  fontSize="18px"
                  text="Prezado cliente, pedimos a gentileza de efetuar o pagamento antecipado para garantir a reserva do horário desejado."
                />
                <hr className="border-warning border-3 mb-5 mt-5" />
              </Col>
              <Col>
                <Subtitle text="Pagamento" />
              </Col>
              <Col>
                <Paragraph text="Escolha sua forma de pagamento:" />
              </Col>
            </>
          )}
          <Container className="my-5">
            {step === 0 && (
              <>
                <Col lg={12} className="pt-2">
                  {controlRender ? (
                    <CardConfirmarConsultaAdv data={dadosCard} />
                  ) : (
                    <></>
                  )}
                </Col>
                {valor === 0 && controlRender ? (
                  <PagamentoPlano
                    advId={advId}
                    date={date}
                    time={time}
                    valorConsulta={valor}
                    changeStep={changeStepPlano}
                  />
                ) : (
                  <>
                    <Col className="my-4">
                      <Subtitle text="Explique sobre seu caso" />
                      <Paragraph text="Para melhor atendimento, precisamos entender o seu caso..." />
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        height: "200px",
                        overflowY: "scroll",
                      }}
                    >
                      <Form>
                        <Form.Group>
                          <Col className="text-center">
                            <Form.Control
                              as="textarea"
                              maxLength={1000}
                              style={{ height: "12rem" }}
                              value={comentario}
                              onChange={(e) => setComentario(e.target.value)}
                              placeholder="Digite aqui."
                            />
                          </Col>
                        </Form.Group>
                      </Form>
                    </Col>
                  </>
                )}
              </>
            )}
            {
              <Col style={{ display: step === 1 ? "block" : "none" }}>
                <Col>
                  <Subtitle text="Pagamento" />
                </Col>
                <Col>
                  <Paragraph text="Escolha sua forma de pagamento:" />
                </Col>
                <br />
                <br />
                <Tabs
                  defaultActiveKey="Chat"
                  id="justify-tab-example"
                  className="custom-tabs"
                  justify
                  activeKey={activeTab}
                  onSelect={handleTabSelect}
                >
                  <Tab eventKey="Particular" title="Cartão de Crédito">
                    {controlRender ? (
                      <PagamentoParticular
                        add_id={dados.advogado_id}
                        date={date}
                        time={time}
                        valorConsulta={valor}
                        changeValues={changeStep}
                        handleBack={handleBack}
                        comentario={comentario}
                      />
                    ) : (
                      <></>
                    )}
                  </Tab>
                  {/* <Tab eventKey="Consignado" title="Consignado">
                {/* <Container>
                  <Row>
                    <Col lg={6} className="ps-4">
                      <Paragraph text="Ao selecionar esta opção você está optando por realizar o pagamento de forma consignada. A escolha do método de pagamento consignado é exclusiva para servidores públicos dos órgãos conveniados a instituições parceiras ao Faça Jus." />
                    </Col>
                  </Row>
                </Container> 
                {controlRender ? (
                  <PagamentoConsignado
                    adv_id={advId}
                    add_id={dados.advogado_id}
                    valorConsulta={valor}
                    changeValues={changeStep}
                    handleBack={handleBack}
                    comentario={comentario}
                  />
                ) : (
                  <></>
                )}
              </Tab> */}
                </Tabs>
              </Col>
            }
            {step === 2 && (
              <>
                <Row className="ag-step-resumo">
                  <Col lg={2}>
                    <Image src={image} />
                  </Col>
                  <Col lg={10}>
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        valor == 0 ? (
                          <span class="resumo-bold">
                            Agendamento por plano realizado com sucesso!
                          </span>
                        ) : (
                          <span class="resumo-bold">
                            Pagamento realizado com sucesso!
                          </span>
                        )
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          <span class="resumo-bold">
                            Sua consulta está agendada para:
                          </span>
                          {" "} {formattedDate} às {" "} {time} horas.
                        </span>
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          <span class="resumo-bold">Notificações:</span> você
                          receberá uma notificação alguns minutos antes da sua
                          consulta começar.
                        </span>
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          <span class="resumo-bold">
                            Consulte seu agendamento:
                          </span>{" "}
                          acesse a seção "Consultas" no Faça Jus para visualizar
                          seu agendamento.
                        </span>
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          <span class="resumo-bold">
                            Realização da Consulta:
                          </span>{" "}
                          a consulta será realizada por vídeo chamada, e o link
                          será disponibilizado na seção "Consultas", 10 minutos
                          antes do horário agendado.
                        </span>
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          <span class="resumo-bold">
                            Cancelamento e Reembolso:
                          </span>{" "}
                          caso tenha um imprevisto e precise cancelar, consulte
                          nossa política de reembolso.
                        </span>
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          <span class="resumo-bold">
                            Orientações para a Reunião:
                          </span>{" "}
                          escolha um ambiente bem iluminado e com boa conexão à
                          internet para garantir uma consulta sem interrupções.
                        </span>
                      }
                    ></Paragraph>
                    <br />
                    <Paragraph
                      fontSize="1.375em"
                      text={
                        <span>
                          Estamos à disposição para qualquer dúvida ou
                          assistência adicional!
                        </span>
                      }
                    ></Paragraph>
                  </Col>
                </Row>
              </>
            )}
          </Container>
          {step !== 1 || !activeTab ? (
            <Col lg={6} className="d-lg-flex column-gap-2 mt-5 pg-step-buttons">
              {step == 0 || (step == 1 && !activeTab) ? (
                valor > 0 ? (
                  <>
                    <Btn
                      type={"terciary"}
                      isSumbit={false}
                      text="Voltar"
                      onTouchable={handleBack}
                    />
                    <Btn
                      type={"secondary-load"}
                      isLoading={isLoading}
                      onTouchable={changeStep}
                      text="Próximo"
                    />
                  </>
                ) : null
              ) : step == 2 ? (
                <Btn
                  type={"secondary-load"}
                  isLoading={isLoading}
                  onTouchable={meuPerfil}
                  text="Ir para meu perfil"
                />
              ) : null}
            </Col>
          ) : null}
          <div class="d-lg-flex column-gap-5 mt-5 mb-5 pg-step-buttons col"></div>
        </Row>
        <SairAgendamentoModal show={showModal} onHide={handleCloseModal} />
      </Container>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          {renderAgendamento()}
          <Toasts
            code={optionsToast.code}
            message={optionsToast.message}
            visible={optionsToast.visible}
            setDefaultOptions={() => fecharToast()}
          />
        </Col>
      </Row>
    </Container>
  );
};
