import { Container, Row, Col, Modal } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CardConfirmarConsultaAdv from "../../molecules/CardConfirmarConsultaAdv";
import { Toasts } from "../../utils/Toasts";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { LoginClienteFormsAgendamento } from "../LoginClienteFormsAgendamento";
import "./styles.css";

export const AdvPreAgendamento = ({ nextStep }) => {
  const apiHome = new HomeService();
  const navigate = useNavigate();

  const { advId, date, time } = useParams();

  const [dados, setDados] = useState(null);
  const [controlRender, setControlRender] = useState(false);
  const [disponivel, setDisponivel] = useState(true);
  const [show, setShow] = useState(false);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    setOptionsToast(defaultOptionsToast);
  }

  const req = async () => {
    try {
      const response = await apiHome.getInfosPreAgendamento(advId, date, time);
      if (response.status === true) {
        setDados(response.data);
        if (response?.data?.valor_consulta === 0) {
          nextStep(2);
        }
        sessionStorage.setItem("advId", advId);
        setControlRender(true);
      } else {
        setDisponivel(false);
      }
    } catch (error) {
      console.error("Erro ao obter informações de pré-agendamento:", error);
    }
  };

  useEffect(() => {
    req();
  }, [advId, date, time]);

  const handleBackPage = () => navigate(-1);

  const handleRedirect = () => navigate("/cadastro/cli");

  const handleShow = () => {
    const isLoggedIn = sessionStorage.getItem("logado") ?? false;
    if (isLoggedIn) {
      setShow(false);
      nextStep();
    } else {
      setShow(true);
    }
  };

  const handleClose = () => setShow(false);

  const renderPreAgendamento = () => (
    <Container>
      <Row className="d-block">
        <Col>
          <Col lg={12} className="pt-2">
            {controlRender && <CardConfirmarConsultaAdv data={dados} />}
          </Col>
        </Col>
        <Col className="text-center">
          <hr className="border-warning border-3 mb-5 mt-5" />
          <Paragraph
            fontSize="18px"
            text={
              !disponivel
                ? "Horário não mais disponível"
                : sessionStorage.getItem("logado")
                ? "Prossiga para realizar o pagamento da consulta."
                : "Para agendar consultas, você precisa estar logado no Faça Jus. Caso ainda não tenha, aproveite e realize o seu cadastro."
            }
          />
        </Col>
        <Col className="pg-step-pre-buttons d-lg-flex d-block gap-5 mt-5">
          <Btn
            type="terciary"
            isSumbit={false}
            text="Voltar"
            onTouchable={handleBackPage}
          />
          {disponivel && (
            <Btn
              type="secondary"
              isSumbit={false}
              text="Avançar"
              onTouchable={handleShow}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Modal size="md" show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Container className="d-flex row justify-content-center mb-5">
            <LoginClienteFormsAgendamento
              advId={advId}
              date={date}
              time={time}
              nextStep={nextStep}
            />
            <Col lg={8} md={6} className="d-flex justify-content-center row">
              <Btn
                type="terciary"
                isSumbit={false}
                text="Cadastre-se"
                onTouchable={handleRedirect}
              />
            </Col>
          </Container>
        </Modal>
      </Row>
    </Container>
  );

  return (
    <Container>
      <Row>
        <Col>
          {renderPreAgendamento()}
          <Toasts
            code={optionsToast.code}
            message={optionsToast.message}
            visible={optionsToast.visible}
            setDefaultOptions={fecharToast}
          />
        </Col>
      </Row>
    </Container>
  );
};
