import React, { useState } from "react";
import { Modal, Col, Row, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import {
  format,
  startOfWeek,
  addDays,
  addWeeks,
  subWeeks,
  isAfter,
  addHours,
} from "date-fns";
import "./styles.css";

const AgendaAdvModal = ({ show, onHide, data, advId }) => {
  const navigate = useNavigate();

  const [currentWeek, setCurrentWeek] = useState(
    startOfWeek(new Date(), { weekStartsOn: 0 })
  );

  // Desestruturando os dados da agenda
  const {
    horarios_disponiveis = {},
    horarios_indisponiveis = [],
    consultas_agendadas = [],
  } = data || {};

  const dayLabels = {
    sunday: "Domingo",
    monday: "Segunda-Feira",
    tuesday: "Terça-Feira",
    wednesday: "Quarta-Feira",
    thursday: "Quinta-Feira",
    friday: "Sexta-Feira",
    saturday: "Sábado",
  };

  const getWeekDays = () => {
    return Array.from({ length: 7 }, (_, index) => addDays(currentWeek, index));
  };

  const handlePrevWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
  };

  const handleNavigateToPreAgendamento = (
    advId,
    selectedDate,
    selectedTime
  ) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    navigate(`/Agendamento/${advId}/${formattedDate}/${selectedTime}`);
  };

  const today = new Date();

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Col className="text-center">
          <Subtitle text="Horários do Advogado" />
          <Card.Text className="intervalo-semana">
            {`Semana de ${format(currentWeek, "dd/MM/yyyy")} a ${format(
              addDays(currentWeek, 6),
              "dd/MM/yyyy"
            )}`}
          </Card.Text>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <div className="scrollable-modal-body">
          <Container className="d-flex flex-column">
            <Row className="d-flex text-center justify-content-between">
              {getWeekDays().map((date, index) => {
                const dayKey = format(date, "eeee").toLowerCase();
                const currentDate = format(date, "yyyy-MM-dd");

                // Filtrar horários indisponíveis e consultas agendadas
                const indisponiveis =
                  horarios_indisponiveis?.filter(
                    (item) => item.data === currentDate
                  ) || [];
                const consultas =
                  consultas_agendadas?.filter(
                    (item) => item.data === currentDate
                  ) || [];

                // Mostrar apenas os horários com 24 horas de antecedência
                const horariosDisponiveisFiltrados =
                  horarios_disponiveis[dayKey]?.filter((horario) => {
                    const horarioDateTime = new Date(
                      `${currentDate}T${horario.inicio}:00`
                    );
                    return isAfter(horarioDateTime, addHours(today, 24));
                  }) || [];

                return (
                  <Col
                    key={index}
                    className="coluna-horarios d-flex flex-column align-items-center"
                  >
                    <Card className="bg-data text-center">
                      {dayLabels[dayKey]} {format(date, "dd/MM")}
                    </Card>

                    <div className="horarios-container">
                      {horariosDisponiveisFiltrados.length > 0 ? (
                        horariosDisponiveisFiltrados.map(
                          (horario, horarioIndex) => {
                            const isIndisponivel = indisponiveis.some(
                              (ind) =>
                                ind.inicio === horario.inicio &&
                                ind.fim === horario.fim
                            );

                            const isAgendado = consultas.some(
                              (cons) =>
                                cons.inicio === horario.inicio &&
                                cons.fim === horario.fim
                            );

                            const isDisabled = isIndisponivel || isAgendado;

                            return (
                              <div
                                key={horarioIndex}
                                className={`horario-btn ${
                                  isDisabled ? "disabled" : ""
                                }`}
                                onClick={
                                  isDisabled
                                    ? null
                                    : () =>
                                        handleNavigateToPreAgendamento(
                                          advId,
                                          date,
                                          horario.inicio
                                        )
                                }
                                style={{
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  marginBottom: "5px",
                                  borderRadius: "4px",
                                  backgroundColor: isIndisponivel
                                    ? "#ffe5e5"
                                    : isAgendado
                                    ? "#fff8e1"
                                    : "white",
                                  cursor: isDisabled
                                    ? "not-allowed"
                                    : "pointer",
                                  pointerEvents: isDisabled ? "none" : "auto",
                                  transition: "background-color 0.3s",
                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                                onMouseEnter={(e) => {
                                  if (!isDisabled) {
                                    e.target.style.backgroundColor = "#f0f8ff";
                                  }
                                }}
                                onMouseLeave={(e) => {
                                  if (!isDisabled) {
                                    e.target.style.backgroundColor = "white";
                                  }
                                }}
                              >
                                <Paragraph
                                  text={`${horario.inicio} às ${horario.fim}`}
                                  style={{
                                    color: isIndisponivel
                                      ? "red"
                                      : isAgendado
                                      ? "yellow"
                                      : "inherit",
                                    margin: 0,
                                  }}
                                />
                                {isIndisponivel && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "0.8rem",
                                      fontWeight: "bold",
                                      marginTop: "5px",
                                    }}
                                  >
                                    Indisponível
                                  </span>
                                )}
                                {isAgendado && (
                                  <span
                                    style={{
                                      color: "yellow",
                                      fontSize: "0.8rem",
                                      fontWeight: "bold",
                                      marginTop: "5px",
                                    }}
                                  >
                                    Consulta
                                  </span>
                                )}
                              </div>
                            );
                          }
                        )
                      ) : (
                        <Paragraph
                          text="Sem horários disponíveis"
                          style={{ color: "#aaa" }}
                        />
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Btn
          type={"secondary-sm"}
          text="< Semana Anterior"
          isSubmit={false}
          onTouchable={handlePrevWeek}
        />
        <Btn
          type={"secondary-sm"}
          text="Próxima Semana >"
          isSubmit={false}
          onTouchable={handleNextWeek}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AgendaAdvModal;
