
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AppRouter from '../src/routes/AppRouter';
import React, { useState, useRef, useEffect } from "react";

function App() {
    return (
        <AppRouter />
    );
}

export default App;
