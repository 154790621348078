import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import Form from "react-bootstrap/Form";
import Subtitle from "../../atoms/Subtitle";
import * as formik from "formik";
import * as yup from "yup";
import { CadastroService } from "../../../services/AdvogadoServices/CadastroService";
import { LoginService } from "../../../services/AdvogadoServices/LoginService";
import { UserService } from "../../../services/UserServices/UserService";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import { Col, Container, FloatingLabel, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { Toasts } from "../../utils/Toasts";
import ProgressBar from "react-bootstrap/ProgressBar";
import DropdownBtn from "../../atoms/DropdownBtn";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { values } from "lodash";

export const CadastroAdvogadoForms = () => {
  const { Formik } = formik;
  let Api = new CadastroService();
  let ApiLogin = new LoginService();
  let apiUser = new UserService();

  const [step, setStep] = useState(1);
  const [apiError, setApiError] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  // Valores formulario Step1
  const [defaultNome, setDefaultNome] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultTelefone, setDefaultTelefone] = useState("");
  const [defaultConfirmaTelefone, setDefaultConfirmaTelefone] = useState("");
  const [defaultCpf, setDefaultCpf] = useState("");

  // Valores formulario Step2
  const [defaultSenha, setDefaultSenha] = useState("");
  const [defaultConfirmaSenha, setDefaultConfirmaSenha] = useState("");

  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidade, setCidade] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedCidade, setSelectedCidade] = useState("");

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState(eyeOff);

  const handleToggle2 = () => {
    if (type2 === "password") {
      setIcon2(eye);
      setType2("text");
    } else {
      setIcon2(eyeOff);
      setType2("password");
    }
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  function obterDataAtual() {
    const dataAtual = new Date();

    const ano = dataAtual.getFullYear();
    const mes = String(dataAtual.getMonth() + 1).padStart(2, "0");
    const dia = String(dataAtual.getDate()).padStart(2, "0");

    return `${ano}-${mes}-${dia}`;
  }

  const dataAtual = obterDataAtual();

  const schema1 = yup.object().shape({
    nome: yup
      .string()
      .required("O nome é obrigatório")
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, "Por favor, digite apenas letras e acentos")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado")
      .test("sobrenome", "Por favor, digite seu sobrenome", function (value) {
        if (!value) return false;
        const partes = value.trim().split(" ");
        if (partes.length < 2) return false; // Menos de duas partes (nome e sobrenome)
        return /[a-zA-Z]/.test(partes[1]); // Verifica se o segundo elemento (sobrenome) contém letras
      }),
    email: yup
      .string()
      .required("Email é obrigatório")
      .test("formato-email", "Formato de e-mail inválido", (value) => {
        const regex = /@/;
        return regex.test(value);
      }),

    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
    telefone: yup
      .string()
      .test(
        "telefone",
        "O Telefone deve conter exatamente 11 dígitos",
        (value) => {
          if (value) {
            return /^\(\d{2}\) \d{5}-\d{4}$/.test(value);
          }
          return false;
        }
      ),
    confirma_telefone: yup
      .string()
      .oneOf([yup.ref("telefone")], "Os celulares devem ser iguais.")
      .required("A confirmação do celular é obrigatória."),
  });

  const schema2 = yup.object().shape({
    senha: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\.])[a-zA-Z\d!@#$%^&*()\.]{8,}$/,
        "Verifique se sua senha cumpre os requisitos mínimos."
      )
      .required("A senha é obrigatória."),
    confirma_senha: yup
      .string()
      .oneOf([yup.ref("senha")], "As senhas devem ser iguais.")
      .required("A confirmação de senha é obrigatória."),
  });

  const verificarCadastroExistente = async (values) => {
    setLoading(true);
    try {
      const response = await apiUser.verificaCadastroExistente(
        values.cpf,
        values.email,
        values.telefone,
        (values.tipo = "ADVOGADO")
      );
      if (response.mensagem.codigo === "101") {
        setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
        setStep((prevStep) => prevStep + 1);
        setValidated(true);
      } else {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
        setLoading(false);
        return;
      }
    } catch (error) {
      let optionsToast = {
        code: "500",
        message: "Erro ao verificar cadastro. Tente novamente.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setLoading(true);

    if (values.telefone != values.confirma_telefone) {
      let optionsToast = {
        code: "102",
        message: "Os celulares devem ser iguais.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      setLoading(false);
      return;
    } else if (!selectedCidade || !selectedItem) {
      let optionsToast = {
        code: "102",
        message: "Cidade e Estado são obrigatórios.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      setLoading(false);
    } else {
      setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
      setStep((prevStep) => prevStep + 1);
      setValidated(true);
    }
  };

  const handleLoading = (clicked) => {
    if (clicked) {
    }
  };

  const backStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    async function fetchCidades() {
      try {
        const response = await Api.listaCidadesEstado(selectedItem);
        if (response && response.data) {
          const nomesCidades = response.data.map((item) => ({
            desc: item.cid_nome,
          }));
          setCidade(nomesCidades);
        }
      } catch (error) {
        console.error("Falha na busca por Cidades", error);
      }
    }

    if (selectedItem) {
      fetchCidades();
    } else {
      // Limpar a lista de cidades se nenhum estado estiver selecionado
      setCidade([]);
    }
  }, [selectedItem]);

  const handleItemClickCidade = (selectedItem) => {
    setSelectedCidade(selectedItem);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Api.getEstados();
        if (response && response.data) {
          const nomesEstados = response.data.map((item) => ({
            desc: item.est_nome,
          }));
          setEstados(nomesEstados);
        }
      } catch (error) {
        console.error("Falha na busca por Estados", error);
      }
    }

    fetchData();
  }, []);

  const handleItemClick = (selectedItem) => {
    setSelectedItem(selectedItem);
  };

  useEffect(() => {
    if (apiError) {
      setTimeout(() => {
        navigate("/cadastro/adv", setStep(1));
      }, 3100);
    }
  }, [apiError]);

  useEffect(() => {
    if (step === 0) {
      navigate("/LandingPageAdv");
    }

    if (step === 3) {
      setLoading(true);
      Api.postCadastrar(
        formValues.cpf,
        formValues.nome,
        formValues.email,
        formValues.telefone,
        formValues.senha,
        selectedItem,
        selectedCidade
      ).then((data) => {
        if (data.status === true) {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };

          setOptionsToast(optionsToast);
          setApiError(false);
          setTimeout(() => {
            // Call login API after successful registration
            ApiLogin.postLogin(formValues.cpf, formValues.senha)
              .then((loginData) => {
                if (loginData.status === true) {
                  sessionStorage.setItem("token", loginData.data.tokenId.dados);
                  sessionStorage.setItem("email", loginData.data.email);
                  sessionStorage.setItem("acessos", "");
                  sessionStorage.setItem("nome", loginData.data.name);
                  sessionStorage.setItem("id", loginData.data.id);
                  sessionStorage.setItem(
                    "fotoPerfil",
                    loginData.data.fotoPerfil
                  );
                  // sessionStorage.setItem("plano", loginData.data.plano);
                  sessionStorage.setItem(
                    "etapa_1",
                    loginData.data.verificaCadastro.etapa_1
                  );
                  sessionStorage.setItem(
                    "etapa_2",
                    loginData.data.verificaCadastro.etapa_2
                  );
                  sessionStorage.setItem(
                    "etapa_3",
                    loginData.data.verificaCadastro.etapa_3
                  );
                  sessionStorage.setItem(
                    "advogadoAtivo",
                    loginData.data.verificaCadastro.advogadoAtivo
                  );
                  sessionStorage.setItem(
                    "boasVindas",
                    loginData.data.verificaCadastro.boasVindas
                  );
                  sessionStorage.setItem("tipo", loginData.data.tipo);
                  sessionStorage.setItem("logado", true);
                }
              })
              .catch((loginError) => {
                console.error("Login Error:", loginError);
              });

            setTimeout(function () {
              navigate("/");
            }, 3100);
          });
        } else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setLoading(false);
          setOptionsToast(optionsToast);
          backStep();
        }
      });
    }
  }, [step]);

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={async (values) => {
              const confirmaTelefone = functions.removeMascara(
                values.confirma_telefone
              );
              // Salva as informacoes do formulario ao submitar
              setDefaultNome(values.nome);
              setDefaultEmail(values.email);
              setDefaultTelefone(values.telefone);
              setDefaultConfirmaTelefone(values.confirma_telefone);
              setDefaultCpf(values.cpf);

              // Remover mascara antes de enviar info ao back
              const { telefone, cpf, confirma_telefone, ...rest } = values;
              const telefoneSemMascara = functions.removeMascara(telefone);
              const confirmaTelefoneSemMascara =
                functions.removeMascara(confirma_telefone);
              const cpfSemMascara = functions.removeMascara(cpf);
              const valuesSemMascara = {
                ...rest,
                telefone: telefoneSemMascara,
                confirma_telefone: confirmaTelefoneSemMascara,
                cpf: cpfSemMascara,
              };
              // Verifica se o cadastro já existe antes de continuar
              const resultadoVerificacao = await verificarCadastroExistente(
                valuesSemMascara
              );
              if (!resultadoVerificacao) {
                return;
              }
              handleSubmit(valuesSemMascara);
            }}
            initialValues={{
              nome: defaultNome,
              email: defaultEmail,
              telefone: defaultTelefone,
              confirma_telefone: defaultConfirmaTelefone,
              cpf: defaultCpf,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="nome" className="mt-3">
                  <FloatingLabel label={"Nome e sobrenome"}>
                    <Form.Control
                      maxLength={80}
                      className={`input-register-login-facj ${
                        touched.nome && errors.nome ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="nome"
                      value={values.nome}
                      onChange={handleChange}
                      isValid={touched.nome && !errors.nome}
                      placeholder={"Nome e sobrenome"}
                    />
                    {touched.nome && errors.nome && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.nome}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="" controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="email">
                  <FloatingLabel label={"Email"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      placeholder="E-mail"
                    />
                    {touched.email && errors.email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="telefone">
                  <FloatingLabel label={"Celular"}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.telefone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.telefone && errors.telefone
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="telefone"
                          value={values.telefone}
                          onChange={handleChange}
                          isValid={touched.telefone && !errors.telefone}
                          placeholder="Telefone"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.telefone && errors.telefone && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.telefone}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="confirma_telefone">
                  <FloatingLabel label={"Confirme seu celular"}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.confirma_telefone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.confirma_telefone &&
                            errors.confirma_telefone
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="confirma_telefone"
                          value={values.confirma_telefone}
                          onChange={handleChange}
                          isValid={
                            touched.confirma_telefone &&
                            !errors.confirma_telefone
                          }
                          placeholder="Confirme seu celular"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.confirma_telefone && errors.confirma_telefone && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_telefone}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <ParagraphSmall
                    textSize="13px"
                    text="Nos próximos passos enviaremos um SMS com um código
                    para confirmar seu número de celular."
                  />
                </Form.Group>

                <Row className="mt-4">
                  <Col className="dropdown-index">
                    <DropdownBtn
                      text="UF"
                      type={"primary"}
                      options={estados}
                      onPress={(selectedItem) => handleItemClick(selectedItem)}
                      defaultValue={selectedItem}
                    />
                  </Col>
                  <Col className="dropdown-index">
                    <DropdownBtn
                      text="Cidade"
                      type={"primary"}
                      defaultValue={selectedCidade}
                      options={
                        cidade.length > 0
                          ? cidade
                          : [{ desc: "Selecione um estado" }]
                      }
                      onPress={(selectedItem) =>
                        handleItemClickCidade(selectedItem)
                      }
                    />
                  </Col>
                </Row>

                <Col className="mt-3 mb-3">
                  <ProgressBar
                    now={20}
                    label={`${20}%`}
                    animated={loading}
                    className=""
                  />
                </Col>

                <Col className="d-lg-flex">
                  <Col className="d-flex justify-content-start">
                    <Btn
                      type={"terciary"}
                      isSumbit={false}
                      text="Voltar"
                      onTouchable={() => backStep()}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Btn type={"secondary"} text="Próximo" isSumbit={true} />
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        );

      case 2:
        return (
          <Formik
            key={step}
            validationSchema={schema2}
            onSubmit={(values) => {
              // Salva as informações do formulario
              setDefaultSenha(values.senha);
              setDefaultConfirmaSenha(values.confirma_senha);
              handleSubmit(values);
            }}
            initialValues={{
              senha: defaultSenha,
              confirma_senha: defaultConfirmaSenha,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="senha">
                  <FloatingLabel label={"Senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirma_senha">
                  <FloatingLabel label={"Confirme sua senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.confirma_senha && errors.confirma_senha
                          ? "is-invalid"
                          : ""
                      }`}
                      type={type2}
                      name="confirma_senha"
                      value={values.confirma_senha}
                      onChange={handleChange}
                      isValid={touched.confirma_senha && !errors.confirma_senha}
                      placeholder="Confirme sua senha"
                    />
                    {touched.confirma_senha && errors.confirma_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_senha}
                      </Form.Control.Feedback>
                    )}
                    <span
                      className="icon-password-facj"
                      onClick={handleToggle2}
                    >
                      <Icon icon={icon2} size={25} />
                    </span>
                  </FloatingLabel>
                  <ParagraphSmall
                    textSize="13px"
                    text="A senha deve conter pelo menos 8 caracteres, um número, uma letra maiúscula, uma letra
                  minúscula e um caractere especial"
                  />
                </Form.Group>

                <Col className="">
                  <ProgressBar
                    now={40}
                    label={`${40}%`}
                    animated={loading}
                    className=""
                  />
                </Col>

                <Col className="d-lg-flex">
                  <Col className="d-flex justify-content-start">
                    <Btn
                      type={"terciary"}
                      isSumbit={false}
                      text="Voltar"
                      onTouchable={() => backStep()}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Btn
                      type={"secondary-load"}
                      onClick={() => handleLoading(true)}
                      isSumbit={true}
                      text="Cadastrar"
                      isLoading={loading}
                    />
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="container-register-facj">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row>
        <Col className="mb-3">
          <Subtitle text={"Crie seu perfil"} />
          <ParagraphSmall
            text={
              step === 2 ? "Cadastre sua senha" : "Insira seus dados pessoais:"
            }
          ></ParagraphSmall>
          {renderForm()}
        </Col>
      </Row>
    </Container>
  );
};
