import React, { useEffect, useState } from "react";
import { Calendar, Badge } from "rsuite";
import "./styles.css";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { Col, Container, Row } from "react-bootstrap";
import { AgendaService } from "../../../services/AdvogadoServices/AgendaService";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import { format } from "date-fns";

const Calendario = ({ selectedDate, buscarDadosDoDia }) => {
  const [horariosLivres, setHorariosLivres] = useState({});
  const [consultasAgendadas, setConsultasAgendadas] = useState({});
  const [dataAtual, setDataAtual] = useState(null);
  const apiConsultas = new ConsultaService();
  const apiAgenda = new AgendaService();

  useEffect(() => {
    // Carrega todas as consultas agendadas e organiza por data
    apiConsultas
      .listarConsultas()
      .then((response) => {
        if (response.status) {
          const consultasPorData = response.data.reduce((acc, consulta) => {
            const dataConsulta = consulta.data;
            if (!acc[dataConsulta]) {
              acc[dataConsulta] = [];
            }
            acc[dataConsulta].push(consulta);
            return acc;
          }, {});
          setConsultasAgendadas(consultasPorData);
        } else {
          setConsultasAgendadas({});
        }
      })
      .catch((error) => {
        console.error("Erro na busca das consultas:", error);
        setConsultasAgendadas({});
      });

    // Carrega todos os horários livres e organiza-os por dia da semana
    apiAgenda
      .listarAgenda()
      .then((response) => {
        if (response.status && response.data.agenda) {
          setDataAtual(response.data.dataAtual);
          setHorariosLivres(response.data.agenda || {});
        } else {
          setDataAtual(response.data?.dataAtual || null);
          setHorariosLivres({});
        }
      })
      .catch((error) => {
        console.error("Erro na busca da agenda:", error);
        setDataAtual(null);
        setHorariosLivres({});
      });
  }, []);

  // Função para renderizar badges em cada célula do calendário
  function renderCell(date) {
    const formattedDate = format(date, "yyyy-MM-dd");
    const dayOfWeek = format(date, "EEEE").toLowerCase(); // Dia da semana em inglês (monday, tuesday, etc.)

    if (formattedDate < dataAtual) {
      const consultasDoDia = consultasAgendadas[formattedDate];
      if (consultasDoDia?.length > 0) {
        return (
          <Badge
            content="consulta"
            className="badge-size badge-color-consulta calendar-fake-date-badge"
            title="Consultas agendadas"
          />
        );
      }
      return null;
    }

    // Verifica se há consultas agendadas para a data atual
    const consultasDoDia = consultasAgendadas[formattedDate];
    if (consultasDoDia?.length > 0) {
      return (
        <Badge
          content="consulta"
          className="badge-size badge-color-consulta calendar-fake-date-badge"
          title="Consultas agendadas"
        />
      );
    }

    // Verifica se há horários livres para o dia da semana atual
    const horariosDoDia = horariosLivres[dayOfWeek];
    if (horariosDoDia?.length > 0) {
      return (
        <Badge
          content="livre"
          className="badge-size badge-color calendar-fake-date-badge"
          title="Horários disponíveis"
        />
      );
    }

    return null;
  }

  // Função para lidar com a seleção de datas no calendário
  function handleSelect(date) {
    const formattedDate = format(date, "yyyy-MM-dd");
    selectedDate(date);

    // Verifica se buscarDadosDoDia foi passado como prop antes de chamá-lo
    // if (typeof buscarDadosDoDia === "function") {
    //   buscarDadosDoDia(formattedDate);
    // } else {
    //   console.info("Agenda e Consultas do dia");
    // }
  }

  return (
    <Container>
      <Row className="bg-calendar calendar-size rounded shadow">
        <Calendar
          bordered={true}
          compact={true}
          isoWeek={false}
          renderCell={renderCell}
          locale={{
            sunday: "Dom",
            monday: "Seg",
            tuesday: "Ter",
            wednesday: "Qua",
            thursday: "Qui",
            friday: "Sex",
            saturday: "Sáb",
            ok: "OK",
            today: "Hoje",
          }}
          onSelect={handleSelect}
        />
      </Row>
      <Row className="d-flex flex-row align-items-center justify-content-center">
        <Col lg={2}>
          <ParagraphSmall text="Legenda:" />
        </Col>
        <Col lg={9} className="mx-2 d-flex">
          <Col>
            <Badge
              content="Horários livres em sua agenda."
              className="badge-color me-2 badge-size calendar-fake-date-badge"
            />
            <Badge
              content="Consultas agendadas."
              className="badge-color-consulta me-2 badge-size calendar-fake-date-badge"
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Calendario;
