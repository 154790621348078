import React, { useEffect, useState } from "react";
import { Modal, Col, Row, Container, Card, Form, Toast } from "react-bootstrap";
import { Badge } from "rsuite";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import {
  format,
  startOfWeek,
  addDays,
  addWeeks,
  subWeeks,
  isAfter,
  addHours,
} from "date-fns";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { ConsultasService } from "../../../services/ClienteServices/ConsultasService";
import "./styles.css";
import { Toasts } from "../../utils/Toasts";

const ReagendamentoConsultaModal = ({
  show,
  onHide,
  idConsulta,
  idAdvogado,
}) => {
  const apiHome = new HomeService();
  const apiConsultas = new ConsultasService();

  const [motivo, setMotivo] = useState("");
  const [currentWeek, setCurrentWeek] = useState(
    startOfWeek(new Date(), { weekStartsOn: 0 })
  );
  const [horariosDisponiveis, setHorariosDisponiveis] = useState({});
  const [horariosIndisponiveis, setHorariosIndisponiveis] = useState([]);
  const [consultasAgendadas, setConsultasAgendadas] = useState([]);

  const dayLabels = {
    sunday: "Domingo",
    monday: "Segunda-Feira",
    tuesday: "Terça-Feira",
    wednesday: "Quarta-Feira",
    thursday: "Quinta-Feira",
    friday: "Sexta-Feira",
    saturday: "Sábado",
  };

  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const getWeekDays = () => {
    return Array.from({ length: 7 }, (_, index) => addDays(currentWeek, index));
  };

  const handlePrevWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiHome.buscarAgendaAdv(idAdvogado);

        if (response && response.data) {
          const {
            horarios_disponiveis = {},
            horarios_indisponiveis = [],
            consultas_agendadas = [],
          } = response.data;

          setHorariosDisponiveis(horarios_disponiveis);
          setHorariosIndisponiveis(horarios_indisponiveis);
          setConsultasAgendadas(consultas_agendadas);
        } else {
          console.error("Erro ao buscar dados da agenda.");
        }
      } catch (error) {
        console.error("Erro na chamada de API:", error);
      }
    };

    fetchData();
  }, [idAdvogado]);

  const handleReagendar = async (
    date,
    idConsulta,
    horario_inicio,
    horario_fim,
    motivo = ""
  ) => {
    if (typeof motivo !== "string" || !motivo.trim()) {
      setOptionsToast({
        code: "102",
        message: "Por favor, preencha o motivo do reagendamento.",
        visible: true,
      });
      return;
    }

    try {
      const response = await apiConsultas.reagendarConsultaCli(
        date,
        idConsulta,
        horario_inicio,
        horario_fim,
        motivo
      );

      setOptionsToast({
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      setOptionsToast({
        code: "500",
        message: "Ocorreu um erro ao reagendar a consulta.",
        visible: true,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton>
        <Col className="text-center">
          <Subtitle text="Reagendar Consulta - Horários Advogado" />
          <Card.Text className="intervalo-semana">
            {`Semana de ${format(currentWeek, "dd/MM/yyyy")} a ${format(
              addDays(currentWeek, 6),
              "dd/MM/yyyy"
            )}`}
          </Card.Text>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Container className="d-flex flex-column">
          {/* Campo para escrever o motivo */}
          <Form.Group controlId="motivoReagendamento" className="mb-4">
            <Form.Label>
              <strong>Motivo do Reagendamento</strong>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Explique o motivo do reagendamento"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
              isInvalid={!motivo.trim()}
            />
            <Form.Control.Feedback type="invalid">
              Este campo é obrigatório.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Exibição dos horários disponíveis */}
          <Row className="d-flex text-center justify-content-between">
            {getWeekDays().map((date, index) => {
              const dayKey = format(date, "eeee").toLowerCase();
              const currentDate = format(date, "yyyy-MM-dd");

              const indisponiveis =
                horariosIndisponiveis?.filter(
                  (item) => item.data === currentDate
                ) || [];
              const consultas =
                consultasAgendadas?.filter(
                  (item) => item.data === currentDate
                ) || [];

              // Filtrando os horários disponíveis com base na regra de 24 horas
              const horariosDisponiveisFiltrados =
                horariosDisponiveis[dayKey]?.filter((horario) => {
                  const horarioDateTime = new Date(
                    `${currentDate}T${horario.inicio}:00`
                  );
                  return isAfter(horarioDateTime, addHours(new Date(), 24)); // Verificação de 24 horas
                }) || [];

              return (
                <Col
                  key={index}
                  className="coluna-horarios d-flex flex-column align-items-center"
                >
                  <Card className="bg-data text-center">
                    {dayLabels[dayKey]} {format(date, "dd/MM")}
                  </Card>

                  <div className="horarios-container">
                    {horariosDisponiveisFiltrados.length > 0 ? (
                      horariosDisponiveisFiltrados.map(
                        (horario, horarioIndex) => {
                          const isIndisponivel = indisponiveis.some(
                            (ind) =>
                              ind.inicio === horario.inicio &&
                              ind.fim === horario.fim
                          );

                          const isAgendado = consultas.some(
                            (cons) =>
                              cons.inicio === horario.inicio &&
                              cons.fim === horario.fim
                          );

                          const isDisabled = isIndisponivel || isAgendado;

                          return (
                            <div
                              key={horarioIndex}
                              className={`horario-btn ${
                                isDisabled ? "disabled" : ""
                              }`}
                              onClick={
                                isDisabled
                                  ? null
                                  : () =>
                                      handleReagendar(
                                        currentDate,
                                        idConsulta,
                                        horario.inicio,
                                        horario.fim,
                                        motivo
                                      )
                              }
                              style={{
                                padding: "10px",
                                border: "1px solid #ccc",
                                marginBottom: "5px",
                                borderRadius: "4px",
                                backgroundColor: isIndisponivel
                                  ? "#ffe5e5"
                                  : isAgendado
                                  ? "#fff8e1"
                                  : "white",
                                cursor: isDisabled ? "not-allowed" : "pointer",
                                pointerEvents: isDisabled ? "none" : "auto",
                                transition: "background-color 0.3s",
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              onMouseEnter={(e) => {
                                if (!isDisabled) {
                                  e.target.style.backgroundColor = "#f0f8ff";
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (!isDisabled) {
                                  e.target.style.backgroundColor = "white";
                                }
                              }}
                            >
                              <Paragraph
                                text={`${horario.inicio} às ${horario.fim}`}
                                style={{
                                  color: isIndisponivel
                                    ? "red"
                                    : isAgendado
                                    ? "yellow"
                                    : "inherit",
                                  margin: 0,
                                }}
                              />
                              {isIndisponivel && (
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                  }}
                                >
                                  <i
                                    className="fas fa-ban"
                                    style={{ marginRight: "5px" }}
                                  ></i>
                                  Indisponível
                                </span>
                              )}
                              {isAgendado && (
                                <span
                                  style={{
                                    color: "yellow",
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                  }}
                                >
                                  <i
                                    className="fas fa-calendar-check"
                                    style={{ marginRight: "5px" }}
                                  ></i>
                                  Consulta
                                </span>
                              )}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <Paragraph text="Sem horários disponíveis" />
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <Btn
          type={"secondary-sm"}
          text="< Semana Anterior"
          isSubmit={false}
          onTouchable={handlePrevWeek}
        />
        <Btn
          type={"secondary-sm"}
          text="Próxima Semana >"
          isSubmit={false}
          onTouchable={handleNextWeek}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ReagendamentoConsultaModal;
