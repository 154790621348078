import functions from "../../components/utils/functions";
import api from "../Api";


export class PerfilService {

async postPerfilAdv(
  ) {
    return await api.post("/advogado/dados-pessoais-adv-perfil", {
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
}

  async postAlteraDadosPessoaisAdvogado(
  nome,
  celular,
  email,
  resumo
  ) {
  return await api.post("/advogado/altera-dados-pessoais-adv", {
    "nome": nome,
    "celular": celular,
    "email": email,
    "resumo": resumo
  }, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
  }).then((res) =>  res.data)
    .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
    });
}


  async postAlteraDadosConsulta(
  valor,
  tempo
  ) { return await api.post("/advogado/altera-dados-consultas", {
  "valor" : valor,
  "tempo" : tempo
  }, {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  },
  }).then((res) =>  res.data)
    .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
    });
}


  async postAlterarSenha(
    senha,
    nova_senha,
    confirma_senha
  ) { return await api.post("/advogado/alterar-senha", {
    "senha": senha,
    "nova_senha": nova_senha,
    "confirma_senha" : confirma_senha
    }, {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } 
    });
  }

  async adicionarFoto(foto) {
    let formData = new FormData();
    // formData.append('id', id);
    formData.append('foto', foto);
    
    return await api.post("/fotoUsuario/adicionar-foto", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
       },
     })
     .then((res) => res.data)
     .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
     });
  }

  async adicionarServicoCorrespondente( 
    servico,
    valorServico
  )  
    { return await api.post("/advogado/adicionar-servico-correspondente", {
      "servico" : servico,
      "valorServico" : valorServico
  }, {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  },
  }).then((res) =>  res.data)
    .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
  });
}

async inativaServicoAdvogado( 
  idServico
)  
  { return await api.post("/advogado/inativa-servico-adv", {
    "idServico" : idServico
}, {
headers: {
  "Content-type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("token"),
},
}).then((res) =>  res.data)
  .catch((error) => {
    if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
      functions.modalToken();
    } 
  });
}
};