import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Form } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import { ClienteService } from "../../../services/AdmClienteServices/ClienteService";
import { parseISO } from "date-fns/esm";
import format from "date-fns-tz/format";
import { utcToZonedTime } from "date-fns-tz";
import { Toasts } from "../../utils/Toasts";
import { ptBR } from "date-fns/locale";
import DropdownBtn from "../../atoms/DropdownBtn";
import { FloatingLabel } from "react-bootstrap";
import InputMask from "react-input-mask";

export const AdmSiteClienteDados = ({ id }) => {
  const navigate = useNavigate();
  const api = new ClienteService();

  const [dadosCliente, setDadosCliente] = useState(null);

  const [planos, setPlanos] = useState([]);
  const [selectedPlano, setSelectedPlano] = useState(null);
  const [plano, setPlano] = useState(null);

  const [dataCon, setDataCon] = useState(null);
  const [dataCancelamento, setDataCancelamento] = useState(null);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleItemClick = (selectedItem) => {
    setSelectedPlano(selectedItem);
  };

  const handleDataChange = ($e) => {
    let treat = $e.target.value.replace(/_/g, "");
    if (treat.replace(/\D/g, "").length == 8) {
      let splitArr = treat.split("/");
      if (splitArr.length == 3) {
        setDataCon(
          new Date(
            splitArr[2],
            Number(splitArr[1]) - 1,
            splitArr[0],
            0,
            0
          ).toISOString()
        );
        // console.log(new Date(splitArr[2], Number(splitArr[1]) -1, splitArr[0], 3, 0).toISOString());
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const userDataResponse = await api.getClientePorId({ id });
        setDadosCliente(userDataResponse);

        if (userDataResponse.data?.planoId) {
          setSelectedPlano({
            id: userDataResponse.data?.planoId,
            desc: userDataResponse.data?.planoNome,
          });
        }
        setPlano(userDataResponse.data?.planoAtivo);
        setDataCancelamento(userDataResponse.data?.dataCancelamento);

        const planosResponse = await api.getPlanos();
        setPlanos(
          planosResponse.data?.map((item) => ({
            id: item.id,
            desc: item.nome,
          }))
        );

        console.log(userDataResponse);
      } catch (error) {
        console.log("Erro ao buscar dados da API:", error);
      }
    }
    fetchData();
  }, []);

  if (!dadosCliente) {
    return <p>Carregando dados do usuário...</p>;
  }

  const handleNavigateBack = () => {
    navigate(-1);
  };

  function formatTelefone(phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    if (cleanedNumber.length === 11) {
      return `(${cleanedNumber.slice(0, 2)}) ${cleanedNumber.slice(
        2,
        7
      )}-${cleanedNumber.slice(7)}`;
    } else {
      return phoneNumber;
    }
  }

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  const formatDate = (dateString) => {
    if (!dateString) {
      return "N/A";
    }
    try {
      return format(
        utcToZonedTime(parseISO(dateString), fusoHorario),
        "dd/MM/yyyy",
        { locale: ptBR }
      );
    } catch (error) {
      return "N/A";
    }
  };

  const handleDesativarPlano = () => {
    if (plano === false) {
      let optionsToast = {
        code: "103",
        message: "Cliente não possui um plano ativo.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      return;
    }

    api
      .desativarPlano(id)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
        setPlano(false);

        setSelectedPlano(null);
        setDataCon(null);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        console.log(error);
        setOptionsToast(optionsToast);
      });
  };

  const handleAtivarPlano = () => {
    if (!selectedPlano || !selectedPlano.id || !dataCon) {
      let optionsToast = {
        code: "102",
        message: "Por favor, informe a data de contratação e o plano.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      return;
    }
    api
      .ativarPlano(id, selectedPlano.id, dataCon)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
        setPlano(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        console.log(error);
        setOptionsToast(optionsToast);
      });
  };

  function handleBloquaerCliente() {}

  const handleValidarCodigo = () => {
    api
      .postValidarCodigoCliente(id)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      });
  };

  return (
    <Container className="d-flex flex-column px-4">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />

      <Row className="pe-3 ">
        <Col lg={3}>
          <Subtitle text="Dados do cliente:" />
        </Col>
        <Col lg={3}>
          <Subtitle text="Status Plano:" />
          <Badge
            pill
            bg={plano ? "success" : "danger"}
            className="m-1 px-4 py-2"
          >
            {plano ? "Ativado" : "Desativado"}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex gap-2">
          <Col lg={6}>
            <ParagraphSmall text="Nome:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={dadosCliente.data.nome} />
            </Col>
          </Col>

          <Col lg={3}>
            <ParagraphSmall text="CPF:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={dadosCliente.data.cpf} />
            </Col>
          </Col>

          <Col lg={3}>
            <ParagraphSmall text="Telefone:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={formatTelefone(dadosCliente.data.telefone)} />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex gap-2">
          <Col lg={6}>
            <ParagraphSmall text="E-mail:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={dadosCliente.data.email} />
            </Col>
          </Col>

          <Col lg={6}>
            <ParagraphSmall text="Data cadastro:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={formatDate(dadosCliente.data.data)} />
            </Col>
          </Col>
        </Col>
      </Row>

      {!dadosCliente.data.status && (
        <Row>
          <Col className="d-flex align-items-end">
            <Col className="">
              <Col className="d-flex align-items-center">
                <ParagraphSmall text="Status:" />

                <Col className="ps-2 d-flex align-items-end">
                  <Badge pill bg="primary" className="m-1 px-4 py-2">
                    Código pendente
                  </Badge>
                </Col>
              </Col>
            </Col>
          </Col>
        </Row>
      )}

      <Row className="pe-3">
        {!dadosCliente.data.planoAtivo &&
        dadosCliente.data.dataCancelamento === "N/A" ? (
          <Form>
            <Form.Group controlId="novo-plano" className="d-lg-flex gap-3">
              <Col sm={6} md={4} lg={4}>
                <ParagraphSmall text="Novo Plano:" />
                <DropdownBtn
                  text={selectedPlano ? selectedPlano.desc : "N/A"}
                  type={"primary"}
                  options={planos}
                  onPress={(selectedItem) => handleItemClick(selectedItem)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <ParagraphSmall text="Nova Data Contratação:" />
                <InputMask mask="99/99/9999" onChange={handleDataChange}>
                  {(inputProps) => (
                    <Col>
                      <Form.Control
                        className="bg-form shadow-sm rounded py-2 px-3"
                        type="text"
                        name="newDate"
                        placeholder="__/__/____"
                        {...inputProps}
                      />
                    </Col>
                  )}
                </InputMask>
              </Col>
            </Form.Group>
          </Form>
        ) : (
          <>
            {dadosCliente.data.planoAtivo === true &&
            dadosCliente.data.dataCancelamento === "N/A" ? (
              <>
                <Col lg={4}>
                  <ParagraphSmall text="Plano:" />
                  <Col className="bg-form shadow-sm rounded py-2 px-3">
                    <Paragraph text={dadosCliente.data.planoNome} />
                  </Col>
                </Col>
                <Col lg={4}>
                  <ParagraphSmall text="Data Contratação:" />
                  <Col className="bg-form shadow-sm rounded py-2 px-3">
                    <Paragraph
                      text={formatDate(dadosCliente.data.dataContratacao)}
                    />
                  </Col>
                </Col>
                <Col lg={4}>
                  <ParagraphSmall text="Data Cancelamento:" />
                  <Col className="bg-form shadow-sm rounded py-2 px-3">
                    <Paragraph
                      text={formatDate(dadosCliente.data.dataCancelamento)}
                    />
                  </Col>
                </Col>
              </>
            ) : null}

            {dadosCliente.data.dataCancelamento !== "N/A" && (
              <Form>
                <Form.Group
                  controlId="plano-desativado"
                  className="d-lg-flex gap-3 mt-4"
                >
                  <Col lg={4}>
                    <ParagraphSmall text="Plano:" />
                    <Col className="bg-form shadow-sm rounded py-2 px-3">
                      <Paragraph text={dadosCliente.data.planoNome} />
                    </Col>
                  </Col>
                  <Col lg={4}>
                    <ParagraphSmall text="Data Contratação:" />
                    <Col className="bg-form shadow-sm rounded py-2 px-3">
                      <Paragraph
                        text={formatDate(dadosCliente.data.dataContratacao)}
                      />
                    </Col>
                  </Col>
                  <Col lg={4}>
                    <ParagraphSmall text="Data Cancelamento:" />
                    <Col className="bg-form shadow-sm rounded py-2 px-3">
                      <Paragraph
                        text={formatDate(dadosCliente.data.dataCancelamento)}
                      />
                    </Col>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col lg={4}>
                      <ParagraphSmall text="Novo Plano:" />
                      <DropdownBtn
                        text={selectedPlano ? selectedPlano.desc : "N/A"}
                        type={"primary"}
                        options={planos}
                        onPress={(selectedItem) =>
                          handleItemClick(selectedItem)
                        }
                      />
                    </Col>
                    <Col lg={4}>
                      <ParagraphSmall text="Nova Data Contratação:" />
                      <InputMask mask="99/99/9999" onChange={handleDataChange}>
                        {(inputProps) => (
                          <Col>
                            <Form.Control
                              className="bg-form shadow-sm rounded py-2 px-3"
                              type="text"
                              name="newDate"
                              placeholder="__/__/____"
                              {...inputProps}
                            />
                          </Col>
                        )}
                      </InputMask>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            )}
          </>
        )}
      </Row>

      <Row>
        <Col>
          <Col className="d-flex align-items-center gap-1 ps-2">
            <Col md={2} className="d-flex gap-3 mb-5">
              {dadosCliente.data.dataCancelamento !== "N/A" &&
              plano !== true ? (
                <Btn
                  type="primary-sm"
                  text="Reativar"
                  onTouchable={handleAtivarPlano}
                />
              ) : plano === true ? (
                <Btn
                  type="primary-sm"
                  text="Desativar Plano"
                  onTouchable={handleDesativarPlano}
                />
              ) : (
                <Btn
                  type="secondary-sm"
                  text="Ativar Plano"
                  onTouchable={handleAtivarPlano}
                />
              )}
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex gap-3 mb-5">
          <Btn
            type="terciary-sm"
            text="Voltar"
            onTouchable={handleNavigateBack}
          />
        </Col>

        <Col className="d-flex gap-3 mb-5">
          <Btn
            type="secondary-sm"
            text="Bloquear"
            onTouchable={handleBloquaerCliente}
          />

          {!dadosCliente.data.status && (
            <Btn
              type="secondary-sm"
              text={"Validar Codigo"}
              onTouchable={handleValidarCodigo}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
