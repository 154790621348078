import React, { useEffect, useState } from "react";
import "./styles.css";
import { Col, Container, Dropdown, FloatingLabel, Form, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { ForumService } from "../../../services/ClienteServices/ForumService";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { format } from "date-fns";
import DropdownBtn from "../../atoms/DropdownBtn";

const nomeUsuario = sessionStorage.getItem("nome") || "";
const primeiraLetra = nomeUsuario.charAt(0);

const ChatCliente = () => {
  const apiForum = new ForumService();

  const [showMessageInput, setShowMessageInput] = useState(false);
  const [advogadoSelecionadoId, setAdvogadoSelecionadoId] = useState(null);
  const [advogado, setAdvogado] = useState(null);
  const [dados, setDados] = useState(null);
  const [clienteData, setClienteData] = useState(null);
  const [advogadosArray, setAdvogadosArray] = useState([]);
  const [mensagens, setMensagens] = useState([]);
  const [mensagemCliente, setMensagemCliente] = useState("");
  const [advogadoId, setAdvogadoId] = useState("");
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [mensagemLida, setMensagemLida] = useState([]);

  const [nomes, setNomes] = useState([]);
  // Passo 1: Variáveis de estado para gerenciar os filtros de data e nome selecionados
  const [selectedDateFilter, setSelectedDateFilter] = useState("");
  const [selectedNameFilter, setSelectedNameFilter] = useState("");
  const cacCliId = sessionStorage.getItem("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiForum.chatCliente(cacCliId, selectedNameFilter);

        if (response.status === true) {
          let advogadosArray = response.data;

          setAdvogadosArray(advogadosArray);

          const nomes = advogadosArray.map((advogado) => advogado.nome_advogado);
          const fotosPerfil = advogadosArray.map((advogado) => advogado.fotoPerfilAdv);
          const statusMensagem = advogadosArray.map((advogado) => advogado.mensagemLida);

          setMensagemLida(statusMensagem)
          setNomes(nomes);
        } else {
          setAdvogadosArray([]);
        }
      } catch (error) {
        console.error("Erro na chamada da API:", error);
      }
    };
    fetchData();
  }, [cacCliId, selectedNameFilter]);


  const handleAvatarClick = async (cac_add_id, cac_cli_id) => {
    if (!cac_add_id || !cac_cli_id) {
      console.error("cac_add_id ou cac_cli_id está vazio");
      return;
    }

    try {
      setAdvogadoId(cac_add_id);

      const response = await apiForum.mensagemCliente(cac_add_id);
      setAdvogadoId(cac_add_id);
      setAdvogadoSelecionadoId(cac_add_id);

      if (response.status === true && response.data.length > 0) {
        setHasNewMessage(true);
        // Os valores que você deseja retornar estão em response.data
        const values = {
          id: response.data.cac_id,
          mensagem: response.data.cac_mensagem,
          data: response.data.cac_data,
          cliente: response.data.cac_enviado_pelo_cliente
        };

        setMensagens(response.data);
      } else {
        // console.log(response);

        if (response.codigo === "102") {
          console.log("Não existem dados cadastrados para este advogado.");
        }

        // Caso não haja mensagens, limpe o estado 'mensagens'
        setMensagens([]);
      }
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  const newMessage = {
    cac_id: advogadoId,
    cac_mensagem: mensagemCliente,
    cac_data: new Date(),
    cac_enviado_pelo_cliente: true,
  };

  const salvarMensagemCliente = () => {
    if (advogadoId) {
      const newMessage = {
        cac_id: advogadoId,
        cac_mensagem: mensagemCliente,
        cac_data: new Date(),
        cac_enviado_pelo_cliente: true,
      };
  
      apiForum
        .salvarNovaMensagemCliente(advogadoId, mensagemCliente)
        .then((data) => {
          setMensagens((mensagensAnteriores) => [
            ...mensagensAnteriores,
            newMessage,
          ]);
          setMensagemCliente("");
  
        })
        .catch((error) => {
          console.log("Erro ao salvar mensagem:", error);
        });
    }
    
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-list");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [mensagens]);

  const [filtroTermo, setFiltroTermo] = useState("");
  const handleFiltroChange = (event) => {
    setFiltroTermo(event.target.value);
  };

  const advogadosArrayFiltrados = advogadosArray.filter(
    (advogado) =>
      advogado.nome_advogado.toLowerCase().includes(filtroTermo.toLowerCase())
  );

  const formatDate = (date) => {
    if (typeof date === 'object') {
      date = date.toISOString();
    }
    if (date && date.split) {
      let dateStr = date.split("T")[0];
      const dataToFormat = new Date();
  
      dataToFormat.setDate(dateStr.split('-')[2]);
      dataToFormat.setMonth(parseInt(dateStr.split('-')[1]) - 1);
      dataToFormat.setFullYear(dateStr.split('-')[0]);
      return format(dataToFormat, "dd/MM/yyyy");
    } else{ 
      return date;
    }
    
  }


  return (
    <Container className="cliente-chat">

      <Row>
        <Col lg={4} className="">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Filtrar por nome"
              value={filtroTermo}
              onChange={handleFiltroChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="">
        <Col
          lg={2}
          className="m-0 border border-2 rounded-start  p-0 text-center gap-2 d-flex row justify-content-center align-items-center advogados-list"
        >
          {advogadosArrayFiltrados.map((advogado, index) => (
            <Col
              key={index}
              className={`clickable-avatar ${advogado.idAdv === advogadoSelecionadoId
                ? "selected-advogado"
                : ""
                }`}
              onClick={() => handleAvatarClick(advogado.idAdv, cacCliId)}
            >
              <Col className="notification-relative">
                {mensagemLida.some((valor) => valor.includes(false)) && (
                  <Col className="d-flex justify-content-end">
                    <Col lg={1} className="bg-warning notification-chat shadow-lg "
                    >1</Col>
                    
                  </Col>
                )}
                <AvatarPerfil
                  type="C"
                  base64={advogado.fotoPerfilAdv}
                  width="4rem"
                  height="4rem"
                  padding="0.1rem"
                  margin="1px"
                  display="none"
                />
              </Col>
              <Col className="d-flex justify-content-center">
                <ParagraphSmall text={advogado.nome_advogado} />
              </Col>
              <Col>
              </Col>
            </Col>
          ))}
        </Col>

        <Col lg={10} className="bg-chat rounded-end">
          <Col className="chat-list  p-3">
            <Row className="">
              {mensagens.map((mensagem, index) => (
                <div key={index}>
                  <div
                    className={
                      mensagem.cac_enviado_pelo_cliente
                        ? "mensagem-cliente"
                        : "mensagem-advogado"
                    }
                  >
                    {mensagem.cac_mensagem}
                  </div>
                  <div
                    className={`data ${mensagem.cac_enviado_pelo_cliente
                      ? "data-cliente"
                      : "data-advogado"
                      }`}
                  >
                    {formatDate(mensagem.cac_data)}
                  </div>
                </div>
              ))}
            </Row>
          </Col>

          <Col className=" d-flex row gap-2 align-items-end-center ">
            <Col lg={1} className="d-flex">
              <AvatarPerfil
                type="B"
                base64={sessionStorage.getItem("fotoPerfil")}
                width="4rem"
                height="4rem"
                padding="0.1rem"
                margin="1px"
                display="none"
              />
            </Col>
            <Col lg={9} className="">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Digite uma nova mensagem"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Mensagem"
                  style={{ height: "5rem" }}
                  value={mensagemCliente}
                  onChange={(e) => setMensagemCliente(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col lg={1}>
              <Btn
                type={"secondary-sm"}
                isSumbit={false}
                text="Enviar"
                disabled= {!advogadoId}
                onTouchable={salvarMensagemCliente}
              />
            </Col>
          </Col>
          <Col className="d-flex justify-content-end"></Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatCliente;
