import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../molecules/Header";

import AdvConsultasAgendadas from "../../organisms/AdvConsultasAgendadas";
import "./styles.css";
import { AdvPreAgendamento } from "../../organisms/PreAgendamento";
import { useParams } from "react-router-dom";
import Footer from "../../molecules/Footer";
const nomeUsuario = sessionStorage.getItem("nome") || "";

export const PreAgendamento = ({ nextStep }) => {
  const { advId, date, time } = useParams();

  const proxStep = (data) => {
    nextStep(data);
  };

  return (
    <div className="mt-5 pt-5">
      <Container fluid className="fluid-bg">
        <Header />
      </Container>
      <Container className="mt-5 pt-5">
        <AdvPreAgendamento
          advId={advId}
          date={date}
          time={time}
          nextStep={proxStep}
        />
      </Container>
      <Container fluid className="fluid-bg">
        <Footer />
      </Container>
    </div>
  );
};
